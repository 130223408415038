import { FetchRegManagementDataQuery } from "@/generated/graphql";
import { ExtractEntityType } from "@/types";

export enum KbaRequestStatus {
  Successful = "Successful",
  Failed = "Failed",
  Approved = "Approved",
  Rejected = "Rejected",
}

export type KbaHistoryItem = ExtractEntityType<
  FetchRegManagementDataQuery["kbaRequests"]
>;
