import { LicensePlateType } from "@/features/registrationTab/types";

export const CUSTOMER_AGE = {
  MIN: 16,
  MAX: 100,
};

export const LICENSE_PLATE_TYPE_LABELS: Record<LicensePlateType, string> = {
  [LicensePlateType.Standard]: "Standard",
  [LicensePlateType.Electric]: "Electric",
};
