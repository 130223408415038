import { Moment } from "moment";
import { differenceInYears, startOfDay } from "date-fns";
import { ReactChild, forwardRef } from "react";
import {
  Typography,
  Row,
  ColProps,
  Col,
  Space,
  Button,
  Divider,
  Input,
  Select,
  Checkbox,
  DatePicker,
  Form,
} from "antd";

import { Salutation, salutationLabels } from "@/constants";
import { CUSTOMER_AGE } from "@/features/registrationTab/constants";

import { OwnerFormSubmitted } from "../types";

import { CustomerDetails, OwnerFormValues, ValidationRule } from "./types";
import { customerToFormValues, formValuesToSubmitted } from "./utils";
import departments from "./departments.json";

interface OwnerFormProps extends CustomerDetails {
  isUpdating?: boolean;
  onCancel: () => void;
  onSubmit: (values: OwnerFormSubmitted) => void;
}

interface FormRowProps extends ColProps {
  children: ReactChild | ReactChild[];
}

const { Title } = Typography;

const FormRow = ({ children, ...colProps }: FormRowProps) => (
  <Row gutter={28}>
    {[children].flat().map((el, idx) => (
      <Col key={idx} {...colProps}>
        {el}
      </Col>
    ))}
  </Row>
);

const REQUIRED = {
  required: true,
  message: "Field is required",
};
const ZIPCODE = {
  pattern: /^[0-9]{5}$/,
  message: "Must be a valid postal code",
};
const dateOfBirthValidationRule: ValidationRule = {
  validator: async (_, value: Moment) => {
    const age = differenceInYears(
      startOfDay(new Date()),
      startOfDay(value.toDate())
    );

    if (age < CUSTOMER_AGE.MIN || age > CUSTOMER_AGE.MAX) {
      throw new Error(
        `Customer age must be between ${CUSTOMER_AGE.MIN} and ${CUSTOMER_AGE.MAX}`
      );
    }
  },
};

export const OwnerForm = forwardRef<HTMLDivElement, OwnerFormProps>(
  ({ isUpdating, primary, secondary, address, onSubmit, onCancel }, ref) => {
    const [form] = Form.useForm<OwnerFormValues>();
    const withCoOwner = Form.useWatch("withCoOwner", form);
    const required = [REQUIRED];

    return (
      <Row justify="center" ref={ref}>
        <Col xs={24} sm={24} md={22}>
          <Form<OwnerFormValues>
            layout="vertical"
            form={form}
            initialValues={customerToFormValues({
              primary,
              secondary,
              address,
            })}
            onFinish={(values) => onSubmit(formValuesToSubmitted(values))}
          >
            <Title level={4} type="secondary">
              Vehicle owner
            </Title>
            <FormRow xs={24} sm={12}>
              <Form.Item name="firstName" label="First Name" rules={required}>
                <Input />
              </Form.Item>
              <Form.Item name="lastName" label="Last Name" rules={required}>
                <Input />
              </Form.Item>
            </FormRow>
            <FormRow xs={24} sm={12}>
              <Form.Item name="street" label="Street" rules={required}>
                <Input />
              </Form.Item>
              <Form.Item name="houseNumber" label="House No" rules={required}>
                <Input />
              </Form.Item>
            </FormRow>
            <FormRow xs={24} sm={12}>
              <Form.Item name="city" label="City" rules={required}>
                <Input />
              </Form.Item>
              <Form.Item
                name="zipcode"
                label="Zip Code"
                style={{ width: "50%" }}
                rules={[REQUIRED, ZIPCODE]}
              >
                <Input />
              </Form.Item>
            </FormRow>
            <Divider dashed />
            <FormRow xs={24} sm={12} lg={8}>
              <Form.Item
                name="cityOfBirth"
                label="City of Birth"
                rules={required}
              >
                <Input />
              </Form.Item>
              <Form.Item
                name="departmentOfBirth"
                label="Department of Birth"
                rules={required}
              >
                <Select showSearch allowClear>
                  {departments.map((value) => (
                    <Select.Option key={value} value={value}>
                      {value}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item
                name="dateOfBirth"
                label="Date of Birth"
                rules={[...required, dateOfBirthValidationRule]}
              >
                <DatePicker style={{ width: "100%" }} />
              </Form.Item>
            </FormRow>
            <Divider dashed />
            <Title level={4} type="secondary">
              Vehicle co-owner
            </Title>
            <FormRow>
              <Form.Item noStyle name="withCoOwner" valuePropName="checked">
                <Checkbox style={{ marginBottom: 24 }}>Add co-owner</Checkbox>
              </Form.Item>
            </FormRow>
            {withCoOwner && (
              <>
                <FormRow xs={24} sm={12}>
                  <Form.Item
                    name="coOwnerSalutation"
                    label="Salutation"
                    rules={required}
                  >
                    <Select allowClear>
                      {Object.values(Salutation).map((value) => (
                        <Select.Option key={value} value={value}>
                          {salutationLabels[value]}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                  <Form.Item
                    name="coOwnerFirstName"
                    label="First Name"
                    rules={required}
                  >
                    <Input />
                  </Form.Item>
                </FormRow>
                <FormRow xs={24} sm={12}>
                  <Form.Item
                    name="coOwnerLastName"
                    label="Last Name"
                    rules={required}
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item
                    name="coOwnerDateOfBirth"
                    label="Date of Birth"
                    rules={[...required, dateOfBirthValidationRule]}
                  >
                    <DatePicker style={{ width: "100%" }} />
                  </Form.Item>
                </FormRow>
              </>
            )}
            <Divider dashed />
            <Row justify="end">
              <Col>
                <Space size="middle">
                  <Button onClick={onCancel}>Cancel</Button>
                  <Button loading={isUpdating} type="primary" htmlType="submit">
                    Save
                  </Button>
                </Space>
              </Col>
            </Row>
          </Form>
        </Col>
      </Row>
    );
  }
);
