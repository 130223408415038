import { useState } from "react";
import { useQuery } from "react-query";
import { message } from "antd";

import { getApiClient } from "@/apiClient";

import { CustomerType, Customer, OwnerFormSubmitted } from "./types";

interface UseCustomerDetailsInput {
  orderId: string;
  onSuccessSubmit?: () => void;
}

interface AlterCustomerInput {
  type: CustomerType;
  payload: null | Partial<
    OwnerFormSubmitted["primary"] & OwnerFormSubmitted["secondary"]
  >;
  orderId: string;
  primary: Customer;
  secondary?: Customer;
}

const findCustomerByType = (
  searched: CustomerType,
  customers: Customer[]
): undefined | Customer => customers.find(({ type }) => type === searched);

const alterCustomer = async ({
  type,
  payload,
  orderId,
  secondary,
  primary,
}: AlterCustomerInput) => {
  const client = getApiClient();
  const existing = type === CustomerType.Secondary ? secondary : primary;

  if (existing && payload) {
    await client.UpdateCustomer({
      orderId,
      customerId: existing.id,
      customer: payload,
    });

    return;
  }

  if (existing && payload == null) {
    await client.DeleteCustomer({
      orderId,
      customerId: existing.id,
    });

    return;
  }

  if (payload) {
    await client.CreateCustomer({
      orderId,
      customer: {
        ...payload,
        type,
        email: primary.email,
        customerType: primary.customerType,
      },
    });
  }
};

export const useCustomerDetails = ({
  orderId,
  onSuccessSubmit,
}: UseCustomerDetailsInput) => {
  const [isUpdating, setIsUpdating] = useState(false);
  const { isLoading, data, refetch } = useQuery(
    ["customerDetails", orderId],
    () => getApiClient().FetchCustomerDetails({ orderId }),
    { refetchOnWindowFocus: false }
  );
  const customerList = (data?.customersByOrder?.[0]?.customers ??
    []) as Customer[];
  const primary = findCustomerByType(CustomerType.Primary, customerList);
  const secondary = findCustomerByType(CustomerType.Secondary, customerList);
  const address = data?.addresses?.find(
    (item) => item?.addressType === "CAR_REGISTRATION"
  )?.address;

  const handleSubmit = async (submitted: OwnerFormSubmitted) => {
    const addressId = address!.id;
    const alterCustomerCommon = {
      orderId,
      primary: primary!,
      secondary,
    };

    setIsUpdating(true);

    await Promise.all([
      alterCustomer({
        ...alterCustomerCommon,
        type: CustomerType.Primary,
        payload: submitted.primary,
      }),
      alterCustomer({
        ...alterCustomerCommon,
        type: CustomerType.Secondary,
        payload: submitted.secondary,
      }),
      getApiClient().UpdateAddress({
        addressId,
        address: submitted.address,
      }),
    ])
      .catch((err) => {
        message.error("Something went wrong");

        throw err;
      })
      .finally(() => {
        setIsUpdating(false);
      });
    onSuccessSubmit?.();
    refetch();
  };

  return {
    isLoading,
    isUpdating,
    primary,
    secondary,
    address,
    handleSubmit,
  };
};
