import { useState, useRef, useEffect } from "react";

import { Section } from "../../components/Section";

import { useCustomerDetails } from "./useCustomerDetails";
import { OwnerDetails } from "./OwnerDetails";
import { OwnerForm } from "./OwnerForm";

interface VehiclePreparationDetailsProps {
  orderId: string;
}

export const VehiclePreparationDetails = ({
  orderId,
}: VehiclePreparationDetailsProps) => {
  const [showForm, setShowForm] = useState(false);
  const formRef = useRef<HTMLDivElement>(null);
  const customer = useCustomerDetails({
    orderId,
    onSuccessSubmit: () => setShowForm(false),
  });
  const title = showForm ? "Update Owner Details" : "Owner Details";

  const switchShowForm = () => setShowForm((show) => !show);

  useEffect(() => {
    if (showForm) {
      formRef.current?.scrollIntoView({ behavior: "smooth" });
    }
  }, [showForm]);

  return (
    <Section title={title} style={{ margin: "20px auto" }}>
      {showForm && customer.primary && customer.address ? (
        <OwnerForm
          ref={formRef}
          isUpdating={customer.isUpdating}
          primary={customer.primary}
          secondary={customer.secondary}
          address={customer.address}
          onSubmit={customer.handleSubmit}
          onCancel={switchShowForm}
        />
      ) : (
        <OwnerDetails
          isLoading={customer.isLoading}
          primary={customer.primary}
          secondary={customer.secondary}
          address={customer.address}
          onFormShow={switchShowForm}
        />
      )}
    </Section>
  );
};
