interface TriggerDownloadInput {
  url: string;
  fileName?: string;
}

export const triggerDownload = ({ url, fileName }: TriggerDownloadInput) => {
  const linkElement = document.createElement("a");

  linkElement.href = url;

  if (fileName) {
    linkElement.download = fileName;
  }

  document.body.appendChild(linkElement);
  linkElement.click();
  document.body.removeChild(linkElement);
};
