import format from "date-fns/format";
import parseISO from "date-fns/parseISO";
import isValid from "date-fns/isValid";

export function formatDateTime(isoDateTime: string): undefined | string {
  const parsed = parseISO(isoDateTime);

  if (!isValid(parsed)) {
    return undefined;
  }

  return format(parsed, "dd/MM/yyyy HH:mm");
}

export function formatDate(isoDateTime: string): undefined | string {
  const parsed = parseISO(isoDateTime);

  if (!isValid(parsed)) {
    return undefined;
  }

  return format(parsed, "dd/MM/yyyy");
}
