import { Typography } from "antd";

import { PAGE_SIZE, useCarRegistration } from "../hooks/useCarRegistration";

import { SearchBar } from "./SearchBar";
import { RegistrationTable } from "./RegistrationTable";

export const RegistrationOverview = () => {
  const carReg = useCarRegistration();

  return (
    <div style={{ padding: "24px 56px" }}>
      <Typography.Title level={3} style={{ marginBottom: 56 }}>
        DIGITAL REGISTRATION OVERVIEW
      </Typography.Title>
      <SearchBar
        style={{
          marginBottom: 24,
          fontWeight: "normal",
        }}
        onFiltersChange={carReg.handleFiltersChange}
      />
      <Typography.Title italic type="secondary" level={4}>
        Digital Car Registrations
        {carReg.total != null ? ` (${carReg.total})` : ""}
      </Typography.Title>
      <RegistrationTable
        loading={carReg.isLoading}
        page={carReg.search.page}
        pageSize={PAGE_SIZE}
        total={carReg.total}
        dataSource={carReg.list}
        onPageChange={carReg.handlePageChange}
      />
    </div>
  );
};
