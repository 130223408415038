import React from "react";
import { Button, Space } from "antd";

import { RegistrationStatus } from "@/constants";
import { useHasPermission } from "@/acl";

import { useDigitalRegistrationManualStatusUpdate } from "../../hooks/useDigitalRegistrationManualStatusUpdate";

interface ActionButtonsProps {
  disableUpdateButton?: boolean;
  registrationId: string;
  registrationStatus: null | undefined | RegistrationStatus;
  onUpdateRequest: () => void;
  onRefetch: () => Promise<void>;
}

export const ActionButtons = ({
  disableUpdateButton,
  registrationId,
  registrationStatus,
  onUpdateRequest,
  onRefetch,
}: ActionButtonsProps) => {
  const hasPermission = useHasPermission();

  const manualStatusUpdate = useDigitalRegistrationManualStatusUpdate({
    registrationId,
    refreshDigitalRegistrationStatus: onRefetch,
  });

  const hasEitherStatus = (...statuses: RegistrationStatus[]) =>
    registrationStatus != null && statuses.includes(registrationStatus);

  return (
    <Space size="middle">
      {hasPermission("editDigitalRegistration") &&
        hasEitherStatus(
          RegistrationStatus.PendingOnCustomerData,
          RegistrationStatus.PendingCustomerSignature
        ) && (
          <Button
            type="primary"
            disabled={disableUpdateButton}
            data-qa-id="update-details-button"
            onClick={onUpdateRequest}
          >
            Update Details
          </Button>
        )}
      {hasPermission("regenerateAndResendDocuments") &&
        hasEitherStatus(RegistrationStatus.PendingCustomerSignature) && (
          <Button
            loading={manualStatusUpdate.isUpdating}
            type="primary"
            onClick={() =>
              manualStatusUpdate.handleUpdate(
                RegistrationStatus.PendingCustomerSignature
              )
            }
            data-qa-id="regenerate-and-resend-documents-for-signature-button"
          >
            Regenerate and resend docs for signature
          </Button>
        )}
    </Space>
  );
};
