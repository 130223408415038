import EditOutlined from "@ant-design/icons/EditOutlined";
import { Button, Table, TableProps } from "antd";
import type { ColumnType } from "antd/es/table";
import format from "date-fns/format";

import { getPathLink, humanizeRegistrationStatus } from "@/utils";
import { RegistrationStatus, RoutePath } from "@/constants";
import { useHasPermission } from "@/acl";

import { CarRegistration, RegSortField, RegPageParams } from "../types";
import { getAdIdFromOrder } from "../utils";

interface RegistrationTableProps
  extends Pick<TableProps<CarRegistration>, "loading" | "dataSource"> {
  page: number;
  pageSize: number;
  total?: null | number;
  onPageChange: (params: RegPageParams) => void;
}

const formatDate = (date: string) => format(new Date(date), "dd/MM/yyyy HH:mm");

const getColumns = ({
  allowEdit,
}: {
  allowEdit?: boolean;
}): ColumnType<CarRegistration>[] => [
  {
    key: "orderNumber",
    title: "Order No.",
    render: (_, { retailOrder }) =>
      retailOrder ? (
        <a target="_blank" href={`/en/orders/${retailOrder.id}`}>
          {retailOrder.orderNumber}
        </a>
      ) : (
        "-"
      ),
  },
  {
    key: "stockNumber",
    title: "Stock No.",
    render: (_, { stockNumber, retailOrder }) => {
      const adId = getAdIdFromOrder(retailOrder);

      return adId ? (
        <a target="_blank" href={`/en/retail-ad/${adId}`}>
          {stockNumber}
        </a>
      ) : (
        stockNumber
      );
    },
  },
  {
    key: "status",
    title: "Registration status",
    render: (_, { status }) =>
      humanizeRegistrationStatus(status as RegistrationStatus),
  },
  {
    key: "assignedToUser",
    title: "Assigned to",
    render: (_, { assignedToUser }) =>
      assignedToUser
        ? `${assignedToUser.firstName} ${assignedToUser.lastName}`
        : "-",
  },
  {
    key: "handoverDate",
    title: "Handover date",
    sorter: true,
    sortDirections: ["ascend", "descend"],
    render: (_, it) => (it.handoverDate ? formatDate(it.handoverDate) : "-"),
  },
  {
    key: "daysUntilHandover",
    title: "Days until handover",
    sorter: true,
    sortDirections: ["ascend", "descend"],
    render: (_, it) => it.daysUntilHandover ?? "-",
  },
  {
    key: "createdOn",
    title: "Created On",
    sorter: true,
    sortDirections: ["descend", "ascend"],
    render: (_, it) => (it.createdOn ? formatDate(it.createdOn) : "-"),
  },
  ...(allowEdit
    ? [
        {
          render: (_, { id }) => (
            <Button
              href={getPathLink(RoutePath.RegistrationQaManagement, {
                registrationId: id,
              })}
              target="_blank"
              shape="round"
              icon={<EditOutlined />}
            />
          ),
        } as ColumnType<CarRegistration>,
      ]
    : []),
];

export const RegistrationTable = ({
  page,
  pageSize,
  total,
  onPageChange,
  ...props
}: RegistrationTableProps) => {
  const hasPermission = useHasPermission();

  return (
    <Table
      {...props}
      rowKey="id"
      columns={getColumns({
        allowEdit: hasPermission("manageDigitalCarRegistration"),
      })}
      pagination={{
        position: ["bottomRight"],
        showSizeChanger: false,
        current: page,
        total: total ?? 0,
        pageSize,
      }}
      onChange={({ current: page = 1 }, _, sorter) => {
        const [{ order, columnKey }] = [sorter].flat();

        onPageChange({
          page,
          sort:
            order && columnKey ? [columnKey as RegSortField, order] : undefined,
        });
      }}
    />
  );
};
