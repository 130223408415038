import React from "react";
import ReactDOM from "react-dom";
import singleSpaReact from "single-spa-react";
import { notification } from "antd";

import { ANTD_EXTERNAL_VERSION_LESS } from "./antdConstants";
import { App } from "./App";
import { RegistrationTabParcel } from "./RegistrationTabParcel";

if (__LOAD_BOOTSTRAP__) {
  /**
     We need to include bootstrap's global styles to ensure that
     the page looks the same either in development mode or when embedded
     into the legacy BO (as it has these global styles)
     */
  require("bootswatch/cosmo/bootstrap.min.css");
}

if (__WEBPACK_DEV_SERVER__) {
  require("antd/dist/antd.less");
}

notification.config({
  prefixCls: `${ANTD_EXTERNAL_VERSION_LESS}-notification`,
});

const errorBoundary = (err: Error, info: React.ErrorInfo) => {
  console.error(err, info);

  return <>Some error occurred!</>;
};

// TODO: make AppDevMode ??

const lifecycles = singleSpaReact({
  React,
  ReactDOM,
  errorBoundary,
  rootComponent: App,
});

export const { bootstrap, mount, unmount } = lifecycles;

export const registrationTab = singleSpaReact({
  React,
  ReactDOM,
  errorBoundary,
  rootComponent: RegistrationTabParcel,
});
