import { useState } from "react";
import { useQuery } from "react-query";
import diffInCalendarDays from "date-fns/differenceInCalendarDays";

import { getApiClient } from "@/apiClient";

import {
  CarRegistration,
  RegFilters,
  RegPageParams,
  RegSearch,
} from "../../types";

import { makeRegRequestSearch } from "./utils";
export { PAGE_SIZE } from "./utils";

const findOrderByOrderNumber = async (orderNumber: string) => {
  const { result } = await getApiClient().ListOrders({
    search: {
      filter: {
        field: "orderNumber",
        op: "eq",
        value: orderNumber,
      },
      page: 0,
      pageSize: 1,
      sorts: null,
    },
  });

  return (result?.entities?.[0] ?? null) as null | { id: string };
};

const listCarRegistrations = async ({ orderNumber, ...search }: RegSearch) => {
  const now = new Date();
  const orderId = orderNumber
    ? (await findOrderByOrderNumber(orderNumber))?.id
    : undefined;

  if (orderNumber && !orderId) {
    return {
      total: 0,
      list: [],
    };
  }

  const { result } = await getApiClient().ListCarRegistrations({
    search: makeRegRequestSearch({
      ...search,
      orderId,
      now,
    }),
  });

  return {
    total: result?.totalEntityCount,
    list: result?.entities?.map<CarRegistration>((it) => {
      const handoverDate = it!.handoverDate && new Date(it!.handoverDate);

      return {
        ...(it as CarRegistration),
        daysUntilHandover:
          handoverDate && handoverDate >= now
            ? diffInCalendarDays(handoverDate, now)
            : null,
      };
    }),
  };
};

export const useCarRegistration = () => {
  const [search, setSearch] = useState<RegSearch>({ page: 1 });
  const query = useQuery(
    ["carRegistrations", search],
    () => listCarRegistrations(search),
    { keepPreviousData: true }
  );
  const { data: { list, total } = {} } = query;
  const isLoading =
    query.isLoading || (query.isFetching && query.isPreviousData);

  const handleFiltersChange = (filters: RegFilters) =>
    setSearch((prev) => ({
      ...prev,
      ...filters,
      page: 1,
    }));

  const handlePageChange = (pageParams: RegPageParams) =>
    setSearch((prev) => ({
      ...prev,
      ...pageParams,
    }));

  return {
    search,
    isLoading,
    total,
    list,
    handleFiltersChange,
    handlePageChange,
  };
};
