import moment from "moment";

import { QaRegPayloadFragment as QaRegPayload } from "@/generated/graphql";

import { RegFormValues } from "./types";

export const payloadToForm = ({
  dateOfBirth,
  ...data
}: Partial<QaRegPayload>): RegFormValues => {
  const relatedFields = [
    "firstName",
    "lastName",
    "cityOfBirth",
    "salutation",
    "doctoralDegree",
    "registrationAddressCity",
    "registrationAddressStreet",
    "registrationAddressHouseNumber",
    "registrationAddressZipCode",
    "vin",
    "licensePlate",
    "licensePlatePin",
    "evbNumber",
    "vehicleTaxesCollectedPeriod",
    "registrationCertificate1Code",
    "registrationCertificate1CodeSecurityCode",
    "registrationCertificate2Number",
    "registrationCertificate2NumberSecurityCode",
    "fuelType",
    "oldLicensePlate",
    "oldLicensePlateFrontSecurityCode",
    "oldLicensePlateRearSecurityCode",
  ] as const;

  return {
    ...relatedFields.reduce<RegFormValues>((values, field) => {
      values[field] = data[field] || undefined;

      return values;
    }, {} as RegFormValues),

    dateOfBirth: dateOfBirth ? moment(dateOfBirth) : undefined,
  };
};

export const formToPayload = ({
  dateOfBirth,
  ...values
}: RegFormValues): QaRegPayload => ({
  ...Object.entries(values).reduce<QaRegPayload>((payload, [key, value]) => {
    payload[key as keyof QaRegPayload] = value || null;

    return payload;
  }, {} as QaRegPayload),

  dateOfBirth: dateOfBirth?.utc(true).startOf("day").toISOString() ?? null,
});
