import type {
  DigitalCarRegEditableDetails as EditableDetails,
  LicensePlate,
} from "@/features/registrationTab/types";

import { FormValues } from "./types";

export const isLicensePlateValid = (licensePlate: LicensePlate): boolean => {
  const parts = (["region", "letters", "digits"] as const).map(
    (part) => licensePlate[part]
  );

  return parts.every(Boolean) || !parts.some(Boolean);
};

export const dataToForm = ({
  bankAccount,
  ...rest
}: EditableDetails): FormValues => ({
  ...rest,
  bankAccount: bankAccount?.details ?? null,
});

export const formToData = ({
  address,
  bankAccount,
  oldLicensePlate,
  ...rootFields
}: FormValues): EditableDetails => ({
  ...rootFields,
  oldLicensePlate:
    oldLicensePlate && Object.values(oldLicensePlate).every(Boolean)
      ? oldLicensePlate
      : null,
  address: address,
  bankAccount: { details: bankAccount },
});
