import { PropsWithChildren } from "react";
import { QueryClientProvider } from "react-query";
import { ConfigProvider as AntdConfigProvider } from "antd";

import { ANTD_EXTERNAL_VERSION_LESS } from "./antdConstants";
import { queryClient } from "./apiClient";
import { PermissionsProvider } from "./acl";

const AntdConfig = ({ children }: PropsWithChildren<{}>) => (
  <AntdConfigProvider
    prefixCls={ANTD_EXTERNAL_VERSION_LESS}
    form={{
      validateMessages: {
        required: "Field is required",
        string: {
          len: "Field must be exactly ${len} characters",
          range: "Field must be between ${min} and ${max} characters",
        },
      },
    }}
  >
    {children}
  </AntdConfigProvider>
);

export const RootContextProvider = ({ children }: PropsWithChildren<{}>) => (
  <AntdConfig>
    <QueryClientProvider client={queryClient}>
      <PermissionsProvider>{children}</PermissionsProvider>
    </QueryClientProvider>
  </AntdConfig>
);
