import { Skeleton, Steps, Typography } from "antd";
import { ClockCircleOutlined } from "@ant-design/icons";

import { Nil } from "@/types";
import { RegistrationStatus } from "@/constants";
import { formatDateTime } from "@/features/registrationTab/utils";
import { humanizeRegistrationStatus } from "@/utils";

import { DigitalCarReg } from "../../types";

import cn from "./styles.less";

interface StatusOverviewProps {
  data: Nil | DigitalCarReg;
  documentsSignedAt: Nil | string;
}

export const DigitalRegistrationStatusOverview = ({
  data,
  documentsSignedAt,
}: StatusOverviewProps) => {
  if (!data) {
    return <Skeleton />;
  }

  return (
    <Steps direction="vertical" className={cn.steps}>
      {STEPS_IN_ORDER.map((step) => {
        const stepData = data.history?.find((el) => el?.step === step);
        const commonProps = {
          key: step,
          title: (
            <Typography.Text strong style={{ textTransform: "uppercase" }}>
              {humanizeRegistrationStatus(step)}
            </Typography.Text>
          ),
          subTitle: stepData?.date ? (
            <span data-qa-selector="status-date">
              {formatDateTime(stepData.date)}
            </span>
          ) : null,
          "data-qa-id": `step-${step}`,
        };

        if (stepData) {
          switch (stepData.status) {
            case "SUCCESS":
              return (
                <Steps.Step
                  {...commonProps}
                  status="finish"
                  {...(step === RegistrationStatus.PendingCustomerSignature &&
                    documentsSignedAt && {
                      description: `Documents signed at: ${formatDateTime(
                        documentsSignedAt
                      )}`,
                    })}
                />
              );

            case "PENDING":
              return (
                <Steps.Step
                  {...commonProps}
                  className={cn.pending}
                  status="wait"
                  icon={<ClockCircleOutlined style={{ fontSize: "32px" }} />}
                />
              );

            default:
              return (
                <Steps.Step
                  {...commonProps}
                  status="error"
                  description={stepData.error?.description}
                />
              );
          }
        }

        return <Steps.Step {...commonProps} />;
      })}
    </Steps>
  );
};

const STEPS_IN_ORDER: RegistrationStatus[] = [
  RegistrationStatus.PendingOnRegistration,
  RegistrationStatus.PendingOnCustomerData,
  RegistrationStatus.PendingCustomerSignature,
  RegistrationStatus.SubmitRequestToKba,
  RegistrationStatus.Approved,
  RegistrationStatus.PrintLicensePlates,
  RegistrationStatus.Completed,
];
