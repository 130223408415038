import React from "react";
import { CardProps, Card } from "antd";

interface SectionProps extends CardProps {
  dataQaId?: string;
}

export const Section = ({ dataQaId, ...rest }: SectionProps) => (
  <Card
    {...rest}
    type="inner"
    headStyle={{ backgroundColor: "rgb(170 170 170 / 32%)" }}
    data-qa-id={dataQaId}
  />
);
