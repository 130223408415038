import { useState } from "react";
import { Typography, Row, Button, Table } from "antd";
import type { ColumnsType } from "antd/es/table";
import CheckCircleOutlined from "@ant-design/icons/CheckCircleOutlined";

import { RegistrationType } from "@/constants";

import { OrderDetails, RegAction } from "../../types";

interface TitleProps {
  orderDetails?: OrderDetails;
}

type OnDone = (action: string) => Promise<unknown>;

interface ActionColumnProps {
  regAction: RegAction;
  onDone?: OnDone;
}

interface RegistrationActionsProps {
  isLoading?: boolean;
  orderDetails?: OrderDetails;
  actions?: RegAction[];
  onDone?: OnDone;
}

const getCarRegLabel = (regType: RegistrationType) => {
  const mapped: Record<RegistrationType, string> = {
    [RegistrationType.SelfRegistration]: "Self Registration",
    [RegistrationType.AhRegistration]: "AH Registration",
    [RegistrationType.AhRegistrationWithLicensePlate]:
      "AH Registration With License Plate",
    [RegistrationType.AhRegistrationTemporary]: "AH Temporary Registration",
    [RegistrationType.AhDigitalRegistration]: "AH Digital Registration",
    [RegistrationType.AhDigitalRegistrationWithCustomPlate]:
      "AH Digital Registration With Custom Plates",
  };

  return mapped[regType];
};

const Title = ({ orderDetails }: TitleProps) => {
  if (!orderDetails) {
    return <Typography.Title level={2}>Overview: -</Typography.Title>;
  }

  return (
    <Typography.Title level={2}>
      Overview:{" "}
      {[
        orderDetails.retailCountry,
        orderDetails.paymentType,
        orderDetails.carRegistrationItems?.[0]
          ? getCarRegLabel(
              orderDetails.carRegistrationItems[0].carRegistration!
                .registrationType as RegistrationType
            )
          : null,
        orderDetails.user?.userType,
      ]
        .filter(Boolean)
        .join(" - ")}
    </Typography.Title>
  );
};

const ActionColumn = ({ regAction, onDone }: ActionColumnProps) => {
  const [isApplying, setIsApplying] = useState(false);

  const handleActionDone =
    onDone &&
    (() => {
      setIsApplying(true);

      onDone(regAction.actionCanonical!).finally(() => {
        setIsApplying(false);
      });
    });

  return (
    <Row justify="center" style={{ minWidth: 90 }}>
      {regAction.applied ? (
        <CheckCircleOutlined
          style={{
            fontSize: 25,
            color: "green",
          }}
        />
      ) : (
        <Button
          type="primary"
          ghost
          disabled={!regAction.canBeApplied}
          loading={isApplying}
          onClick={handleActionDone}
        >
          Done
        </Button>
      )}
    </Row>
  );
};

const makeColumns = ({
  onDone,
}: Pick<RegistrationActionsProps, "onDone">): ColumnsType<RegAction> => [
  {
    title: "Status",
    key: "status",
    dataIndex: "actionHumanReadable",
  },
  {
    title: "State",
    key: "state",
    render: (_, { appliedOn }) => (appliedOn ? `Done: ${appliedOn}` : "-"),
  },
  {
    title: "Actions",
    key: "actions",
    render: (_, regAction) => (
      <ActionColumn regAction={regAction} onDone={onDone} />
    ),
  },
];

export const RegistrationActions = ({
  isLoading,
  orderDetails,
  actions,
  onDone,
}: RegistrationActionsProps) => (
  <>
    <Title orderDetails={orderDetails} />
    <Table
      data-qa-selector="reg-actions-table"
      rowKey="actionCanonical"
      loading={isLoading}
      pagination={false}
      columns={makeColumns({ onDone })}
      dataSource={actions}
    />
  </>
);
