export const MFE_ROOT_PATH = "/:lang/digital-car-registration";

export enum RoutePath {
  RegistrationOverview = "",
  RegistrationQaManagement = "qa-management/:registrationId",
}

export enum RegistrationStatus {
  PendingOnRegistration = "PENDING_ON_REGISTRATION",
  PendingOnCustomerData = "PENDING_ON_CUSTOMER_DATA",
  PendingCustomerSignature = "PENDING_CUSTOMER_SIGNATURE",
  SubmitRequestToKba = "SUBMIT_REQUEST_TO_KBA",
  Approved = "APPROVED",
  PrintLicensePlates = "PRINT_LICENSE_PLATES",
  Completed = "COMPLETED",
  Canceled = "CANCELED",
  Rejected = "REJECTED",
}

export enum RegistrationType {
  SelfRegistration = "SELF_REGISTRATION",
  AhRegistration = "AH_REGISTRATION",
  AhRegistrationWithLicensePlate = "AH_REGISTRATION_WITH_LICENSE_PLATE",
  AhRegistrationTemporary = "AH_REGISTRATION_TEMPORARY",
  AhDigitalRegistration = "AH_DIGITAL_CAR_REGISTRATION",
  AhDigitalRegistrationWithCustomPlate = "AH_DIGITAL_CAR_REGISTRATION_WITH_CUSTOM_PLATE",
}

export enum OrderItemExternalType {
  Ad = 0,
}

export enum CustomerType {
  Primary = "PRIMARY",
  Secondary = "SECONDARY",
}

export enum Salutation {
  Mr = "MR",
  Ms = "MS",
  PreferNotToSay = "PREFER_NOT_TO_SAY",
}

export enum TaxCollectionFrequency {
  Yearly = "YEARLY",
  TwicePerYear = "TWICE_PER_YEAR",
  Quarterly = "QUARTERLY",
}

export const registrationStatusLabels: Record<RegistrationStatus, string> = {
  [RegistrationStatus.PendingOnRegistration]: "Pending on registration",
  [RegistrationStatus.PendingOnCustomerData]: "Pending customer data",
  [RegistrationStatus.PendingCustomerSignature]: "Pending customer signature",
  [RegistrationStatus.SubmitRequestToKba]: "Request sent to KBA",
  [RegistrationStatus.Approved]: "Approved",
  [RegistrationStatus.PrintLicensePlates]: "Print license plates",
  [RegistrationStatus.Completed]: "Registration complete",
  [RegistrationStatus.Canceled]: "Canceled",
  [RegistrationStatus.Rejected]: "Rejected",
};

export const salutationLabels: Record<Salutation, string> = {
  [Salutation.Mr]: "Mr.",
  [Salutation.Ms]: "Ms.",
  [Salutation.PreferNotToSay]: "Prefer not to say",
};

export const taxCollectionFrequencyLabels: Record<
  TaxCollectionFrequency,
  string
> = {
  [TaxCollectionFrequency.Yearly]: "Yearly",
  [TaxCollectionFrequency.TwicePerYear]: "Twice per Year",
  [TaxCollectionFrequency.Quarterly]: "Quarterly",
};
