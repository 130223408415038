import { ReactNode } from "react";
import {
  Row,
  Col,
  Divider,
  Typography,
  Button,
  Input,
  Select,
  DatePicker,
  Form,
  FormItemProps as AntFormItemProps,
} from "antd";

import { QaRegPayloadFragment as QaRegPayload } from "@/generated/graphql";
import {
  TaxCollectionFrequency,
  taxCollectionFrequencyLabels,
} from "@/constants";

import { RegFormValues } from "./types";
import { payloadToForm, formToPayload } from "./utils";

interface RegFormProps {
  initialValues?: Partial<QaRegPayload>;
  qaVariants: QaRegPayload[];
  onSubmit: (payload: QaRegPayload) => void;
}

interface SectionProps {
  title: ReactNode;
  children: ReactNode;
}

const FormItem = (props: AntFormItemProps) => (
  <Col xs={24} md={12}>
    <Form.Item {...props} />
  </Col>
);

const Section = ({ title, children }: SectionProps) => (
  <>
    <Divider orientation="left">
      <Typography.Text italic type="secondary">
        {title}
      </Typography.Text>
    </Divider>
    <Row gutter={{ xs: 0, md: 32, xl: 72 }} style={{ marginBottom: -12 }}>
      {children}
    </Row>
  </>
);

export const RegForm = ({
  initialValues,
  qaVariants,
  onSubmit,
}: RegFormProps) => {
  const [form] = Form.useForm<RegFormValues>();

  const handlePrefill = (variantIndex: number | undefined) => {
    if (variantIndex == null) {
      return form.resetFields();
    }

    if (qaVariants[variantIndex]) {
      form.setFieldsValue(payloadToForm(qaVariants[variantIndex]));
    }
  };

  return (
    <Form<RegFormValues>
      form={form}
      layout="vertical"
      initialValues={initialValues && payloadToForm(initialValues)}
      onFinish={(submitted) => onSubmit(formToPayload(submitted))}
    >
      <Row>
        <Col xs={24} md={8}>
          <Select<number>
            allowClear
            placeholder="Prefill with test data"
            style={{ width: "100%" }}
            options={qaVariants.map((variant, idx) => ({
              value: idx,
              label: `${variant.firstName} ${variant.lastName}`,
            }))}
            onChange={handlePrefill}
          />
        </Col>
      </Row>
      <Section title="Customer">
        <FormItem name="firstName" label="First name">
          <Input />
        </FormItem>
        <FormItem name="lastName" label="Last name">
          <Input />
        </FormItem>
        <FormItem name="cityOfBirth" label="City of birth">
          <Input />
        </FormItem>
        <FormItem name="dateOfBirth" label="Date of birth">
          <DatePicker style={{ width: "100%" }} />
        </FormItem>
        <FormItem name="salutation" label="Gender">
          <Input />
        </FormItem>
        <FormItem name="doctoralDegree" label="Doctoral degree">
          <Input />
        </FormItem>
      </Section>

      <Section title="Registration address">
        <FormItem name="registrationAddressCity" label="City">
          <Input />
        </FormItem>
        <FormItem name="registrationAddressStreet" label="Street">
          <Input />
        </FormItem>
        <FormItem name="registrationAddressHouseNumber" label="House number">
          <Input />
        </FormItem>
        <FormItem name="registrationAddressZipCode" label="Zip code">
          <Input />
        </FormItem>
      </Section>

      <Section title="Digital car registration">
        <FormItem name="vin" label="VIN">
          <Input />
        </FormItem>
        <FormItem name="licensePlate" label="License plate">
          <Input />
        </FormItem>
        <FormItem name="licensePlatePin" label="License plate pin">
          <Input />
        </FormItem>
        <FormItem name="evbNumber" label="EVB number">
          <Input />
        </FormItem>
        <FormItem
          name="vehicleTaxesCollectedPeriod"
          label="Vehicle taxes collected period"
        >
          <Select
            allowClear
            options={Object.values(TaxCollectionFrequency).map((value) => ({
              value,
              label: taxCollectionFrequencyLabels[value],
            }))}
          />
        </FormItem>
        <FormItem
          name="registrationCertificate1Code"
          label="Reg. Certificate I code"
        >
          <Input />
        </FormItem>
        <FormItem
          name="registrationCertificate1CodeSecurityCode"
          label="Reg. Certificate I code security code"
        >
          <Input />
        </FormItem>
        <FormItem
          name="registrationCertificate2Number"
          label="Reg. Certificate II Number"
        >
          <Input />
        </FormItem>
        <FormItem
          name="registrationCertificate2NumberSecurityCode"
          label="Reg. Certificate II Number security code"
        >
          <Input />
        </FormItem>
        <FormItem name="fuelType" label="Fuel type">
          <Input />
        </FormItem>
        <FormItem name="oldLicensePlate" label="Old license plate">
          <Input />
        </FormItem>
        <FormItem
          name="oldLicensePlateFrontSecurityCode"
          label="Old license plate front security code"
        >
          <Input />
        </FormItem>
        <FormItem
          name="oldLicensePlateRearSecurityCode"
          label="Old license plate rear security code"
        >
          <Input />
        </FormItem>
      </Section>

      <Row justify="end" style={{ margin: "24px 0" }}>
        <Col xs={24} md={6}>
          <Button block type="primary" htmlType="submit">
            Save
          </Button>
        </Col>
      </Row>
    </Form>
  );
};
