import { HTMLAttributes } from "react";
import formatDate from "date-fns/format";
import { Button, List, Typography } from "antd";
import DownloadOutlined from "@ant-design/icons/DownloadOutlined";

import { KbaHistoryItem } from "./types";

interface KbaRequestListProps extends HTMLAttributes<HTMLDivElement> {
  isLoading?: boolean;
  items: undefined | KbaHistoryItem[];
  onDownload?: (requestId: string) => void;
}

export const KbaRequestList = ({
  isLoading,
  items,
  onDownload,
  ...props
}: KbaRequestListProps) => (
  <List
    {...props}
    bordered
    loading={isLoading}
    header={
      <Typography.Title type="secondary" level={5} style={{ margin: 0 }}>
        KBA Requests
      </Typography.Title>
    }
    dataSource={items}
    renderItem={(item) => (
      <List.Item
        actions={[
          <Button
            shape="round"
            icon={<DownloadOutlined />}
            onClick={() => onDownload?.(item.id)}
          />,
        ]}
      >
        <List.Item.Meta
          title={
            <>
              <Typography.Text strong>{item.status}</Typography.Text>&nbsp;
              <Typography.Text italic type="secondary">
                ({formatDate(new Date(item.createdOn), "yyyy-MM-dd HH:mm, O")})
              </Typography.Text>
            </>
          }
          description={
            item.errorDescription != null && (
              <Typography.Paragraph
                ellipsis={{ rows: 1, expandable: true, symbol: "more" }}
              >
                {item.errorDescription}
              </Typography.Paragraph>
            )
          }
        />
      </List.Item>
    )}
  />
);
