import { useQueryClient, useQuery, useMutation } from "react-query";
import { notification } from "antd";

import {
  SignatureRequestState as SignatureRequestStatus,
  QaRegPayloadFragment as QaRegPayload,
  FetchRegManagementDataQuery,
} from "@/generated/graphql";
import { ExtractEntityType } from "@/types";
import { RegistrationStatus } from "@/constants";
import { getApiClient } from "@/apiClient";

import { KbaRequestStatus, KbaHistoryItem } from "./types";

const REG_MANAGEMENT_DATA_KEY = "reg-management-data";

const notificationStyle = { marginTop: 35 };

const notifyUnknownError = () =>
  notification.error({
    message: "Something went wrong",
    style: notificationStyle,
  });

interface WithRegId {
  registrationId: string;
}

export type ChangeStatusByType =
  | { registrationStatus: RegistrationStatus }
  | { signatureStatus: SignatureRequestStatus }
  | { kbaStatus: KbaRequestStatus };

type ChangeStatusInput = WithRegId & ChangeStatusByType;

const changeStatus = async ({
  registrationId,
  ...statusChange
}: ChangeStatusInput): Promise<void> => {
  const api = getApiClient();

  if ("registrationStatus" in statusChange) {
    await api.QaChangeRegistrationStatus({
      registrationId,
      status: statusChange.registrationStatus,
    });

    return;
  }

  if ("signatureStatus" in statusChange) {
    await api.QaChangeSignatureRequestStatus({
      registrationId,
      status: statusChange.signatureStatus,
    });

    return;
  }

  const kbaStatusMutations: Record<
    KbaRequestStatus,
    (input: { registrationId: string }) => Promise<unknown>
  > = {
    [KbaRequestStatus.Successful]: api.QaMarkKbaRequestAsSuccessful,
    [KbaRequestStatus.Failed]: api.QaMarkKbaRequestAsFailed,
    [KbaRequestStatus.Approved]: api.QaMarkKbaRequestAsApproved,
    [KbaRequestStatus.Rejected]: api.QaMarkKbaRequestAsRejected,
  };

  await kbaStatusMutations[statusChange.kbaStatus]({
    registrationId,
  });
};

export const getKbaRequestLink = async (requestId: string): Promise<string> => {
  const { url } = await getApiClient().GetKbaRequestLink({
    requestId,
  });

  if (!url) {
    throw new Error("Couldn't generate a URL");
  }

  return url;
};

export const useStatusChanges = ({ registrationId }: WithRegId) => {
  const queryClient = useQueryClient();
  const {
    isLoading,
    variables,
    mutate: change,
  } = useMutation(
    (byType: ChangeStatusByType) => changeStatus({ registrationId, ...byType }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(REG_MANAGEMENT_DATA_KEY);
        notification.success({
          message: "Changed status successfully",
          style: notificationStyle,
        });
      },

      onError: notifyUnknownError,
    }
  );

  const isChangingFor = (byType: ChangeStatusByType): boolean =>
    isLoading &&
    variables != null &&
    ["registrationStatus", "signatureStatus", "kbaStatus"].some((key) => {
      const left = byType as Record<string, string>;
      const right = variables as Record<string, string>;

      return key in left && key in right && left[key] === right[key];
    });

  return {
    isChangingFor,
    change,
  };
};

export const useRegManagement = ({ registrationId }: WithRegId) => {
  const {
    isLoading,
    data: {
      signatureRequests,
      kbaRequests,
      digitalReg,
      qaVariants,
      qaRegDetails,
    } = {},
  } = useQuery(
    [REG_MANAGEMENT_DATA_KEY, registrationId],
    () => getApiClient().FetchRegManagementData({ registrationId }),
    { refetchOnWindowFocus: false }
  );
  const { mutateAsync: putDigitalReg } = useMutation(
    (payload: QaRegPayload) =>
      getApiClient().QaPutDigitalCarReg({
        registrationId,
        payload,
      }),
    {
      onSuccess: () =>
        notification.success({
          message: "Submitted successfully",
          style: notificationStyle,
        }),

      onError: notifyUnknownError,
    }
  );
  const signatureRequest = signatureRequests?.entities?.[0];

  return {
    isLoading,
    signatureRequest,
    digitalReg,
    qaRegDetails: qaRegDetails?.entities?.[0] as
      | undefined
      | ExtractEntityType<FetchRegManagementDataQuery["qaRegDetails"]>,
    kbaRequests: kbaRequests?.entities as undefined | KbaHistoryItem[],
    qaVariants: qaVariants?.entities as undefined | QaRegPayload[],
    putDigitalReg,
  };
};
