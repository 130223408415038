import { RegistrationTab } from "@/features/registrationTab";

import { RootContextProvider } from "./RootContextProvider";

export const RegistrationTabParcel = (props: { orderId: string }) => {
  return (
    <RootContextProvider>
      <RegistrationTab orderId={props.orderId} />
    </RootContextProvider>
  );
};
