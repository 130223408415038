import { GraphQLResponse } from "graphql-request/src/types";
import Cookies from "js-cookie";
import { ClientError, GraphQLClient } from "graphql-request";

import { AccessDomain } from "@/acl";
import { getSdk, Sdk } from "@/generated/graphql";

const client = new GraphQLClient("/api/v1/retail-graphql-gateway");

interface GetApiClientInput {
  domain?: AccessDomain;
}

export const getApiClient = ({
  domain = AccessDomain.OrderManagement,
}: GetApiClientInput = {}) =>
  getSdk(client, (action) =>
    action({
      "access-domain": domain,
      Authorization: `Bearer ${Cookies.get("t")}`,
    })
  );

type QueryVariables<Q extends keyof Sdk> = Parameters<Sdk[Q]>[0];

type QueryData<Q extends keyof Sdk> = Sdk[Q] extends (
  ...args: any
) => Promise<infer D>
  ? D
  : never;

export const runQuery = <Query extends keyof Sdk>(
  query: Query,
  {
    domain,
    variables,
  }: {
    variables: QueryVariables<Query>;
    domain?: AccessDomain;
  }
): Promise<QueryData<Query>> =>
  // @ts-ignore
  getApiClient({ domain })[query](variables);

export const runQueryWithRecovery = <Query extends keyof Sdk, RecoveryData>(
  query: Query,
  {
    variables,
    domain,
    recoverOnClientError,
  }: {
    variables: QueryVariables<Query>;
    recoverOnClientError: (
      response: GraphQLResponse<QueryData<Query>>
    ) => RecoveryData;
    domain?: AccessDomain;
  }
): Promise<QueryData<Query> | RecoveryData> =>
  runQuery(query, { variables, domain }).catch((err) => {
    if (err instanceof ClientError) {
      return recoverOnClientError(
        err.response as GraphQLResponse<QueryData<Query>>
      );
    } else {
      throw err;
    }
  });
