import { useState, useCallback } from "react";
import { message, Typography, Button, Form, Row, Col } from "antd";

import {
  DigitalCarReg,
  DigitalCarRegEditableDetails,
  LicensePlateType,
} from "@/features/registrationTab/types";

import { FormValues } from "./types";
import { isLicensePlateValid, dataToForm, formToData } from "./utils";
import { VehicleDetailsForm } from "./VehicleDetailsForm";
import { RegistrationAddressForm } from "./RegistrationAddressForm";
import { DirectDebitForm } from "./DirectDebitForm";

interface DetailsFormProps {
  isLowEmissionsVehicle: boolean;
  registration: DigitalCarReg;
  onCancel: () => void;
  onSubmit: (details: DigitalCarRegEditableDetails) => Promise<void>;
}

export const DetailsForm = ({
  isLowEmissionsVehicle,
  registration,
  onCancel,
  onSubmit,
}: DetailsFormProps) => {
  const [isSubmitting, setSubmitting] = useState(false);
  const [form] = Form.useForm<FormValues>();
  const licensePlateType = Form.useWatch(
    ["licensePlate", "licensePlateType"],
    form
  );
  const isElectricPlateType =
    isLowEmissionsVehicle && licensePlateType === LicensePlateType.Electric;

  const scrollIntoView = useCallback((el: null | HTMLElement) => {
    el?.scrollIntoView({ behavior: "smooth" });
  }, []);

  const handleSubmit = async (values: FormValues) => {
    if (
      !(values.oldLicensePlate && isLicensePlateValid(values.oldLicensePlate))
    ) {
      return message.error(
        "The Old license plate field cannot be partially filled"
      );
    }

    setSubmitting(true);

    try {
      await onSubmit(formToData(values));
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <Form
      form={form}
      layout="vertical"
      initialValues={dataToForm(registration)}
      onFinish={handleSubmit}
    >
      <Typography.Title level={4} data-qa-id="section-vehicle-details">
        Vehicle Details
      </Typography.Title>
      <VehicleDetailsForm
        ref={scrollIntoView}
        isLowEmissionsVehicle={isLowEmissionsVehicle}
        registrationType={registration.registrationType}
      />
      <Typography.Title level={4} data-qa-id="section-holder-details">
        Holder Details
      </Typography.Title>
      <RegistrationAddressForm />
      {!isElectricPlateType && (
        <>
          <Typography.Title level={4} data-qa-id="direct-debit-details">
            Direct debit details
          </Typography.Title>
          <DirectDebitForm />
        </>
      )}
      <Row justify="end" gutter={12}>
        <Col>
          <Button onClick={onCancel}>Cancel</Button>
        </Col>
        <Col>
          <Button type="primary" htmlType="submit" loading={isSubmitting}>
            Save
          </Button>
        </Col>
      </Row>
    </Form>
  );
};
