import moment, { Moment } from "moment";

import { Salutation } from "@/constants";

import { OwnerFormSubmitted } from "../types";

import { OwnerFormValues, CustomerDetails } from "./types";

export const customerToFormValues = ({
  primary,
  secondary,
  address,
}: CustomerDetails): OwnerFormValues => ({
  firstName: primary.firstName ?? "",
  lastName: primary.lastName ?? "",
  street: address.street ?? "",
  houseNumber: address.houseNumber ?? "",
  city: address.city ?? "",
  zipcode: address.zipcode ?? "",
  dateOfBirth: primary.dateOfBirth ? moment(primary.dateOfBirth) : undefined,
  cityOfBirth: primary.cityOfBirth ?? "",
  departmentOfBirth: primary.departmentOfBirth ?? "",
  withCoOwner: secondary != null,
  coOwnerSalutation: secondary?.salutation as undefined | Salutation,
  coOwnerFirstName: secondary?.firstName ?? "",
  coOwnerLastName: secondary?.lastName ?? "",
  coOwnerDateOfBirth: secondary?.dateOfBirth
    ? moment(secondary.dateOfBirth)
    : undefined,
});

const stringifyDateOfBirth = (date: Moment) =>
  // Addressing Antd issue when it adds trailing time to the date
  date.utc(true).startOf("day").toISOString();

export const formValuesToSubmitted = (
  values: OwnerFormValues
): OwnerFormSubmitted => ({
  primary: {
    firstName: values.firstName,
    lastName: values.lastName,
    dateOfBirth: stringifyDateOfBirth(values.dateOfBirth!),
    cityOfBirth: values.cityOfBirth,
    departmentOfBirth: values.departmentOfBirth,
  },
  secondary: values.withCoOwner
    ? {
        salutation: values.coOwnerSalutation!,
        firstName: values.coOwnerFirstName!,
        lastName: values.coOwnerLastName!,
        dateOfBirth: stringifyDateOfBirth(values.coOwnerDateOfBirth!),
      }
    : null,
  address: {
    street: values.street,
    houseNumber: values.houseNumber,
    city: values.city,
    zipcode: values.zipcode,
  },
});
