import { useCallback, useContext } from "react";

import { PermissionsContext } from "./PermissionsProvider";

const permissionsMap = {
  viewDigitalRegistration: [
    "gqls://order.management/query/digitalcarregistrationbyorderid",
  ],
  editDigitalRegistration: [
    "gqls://order.management/mutation/updatedigitalcarregistration",
  ],
  viewEditBankData: [
    "gqls://order.management/mutation/updatedigitalcarregistrationbankaccount",
  ],
  regenerateAndResendDocuments: [
    "gqls://order.management/query/digitalcarregistration",
    "gqls://order.management/mutation/changeregistrationstatus",
  ],
  manageDigitalCarRegistration: [
    "gqls://order.management/query/digitalcarregistration",
    "gqls://order.management/mutation/qachangeregistrationstatus",
  ],
  manageDigitalCarRegAssignee: [
    "gqls://order.management/query/searchdigitalcarregistrationassigneeusers",
    "gqls://order.management/mutation/updatedigitalcarregistration",
  ],
};

export type Permission = keyof typeof permissionsMap;

export const useHasPermission = () => {
  const allowedPermissions = useContext(PermissionsContext);

  return useCallback(
    (requestedPermissions: Permission[] | Permission) => {
      const permissions = Array.isArray(requestedPermissions)
        ? requestedPermissions
        : [requestedPermissions];

      return permissions.every((permission) =>
        permissionsMap[permission].every(
          (permissionId) => allowedPermissions[permissionId]
        )
      );
    },
    [allowedPermissions]
  );
};
