import { useMutation } from "react-query";

import { RegistrationStatus } from "@/constants";
import { getApiClient } from "@/apiClient";

type Args = {
  registrationId: string;
  refreshDigitalRegistrationStatus: () => Promise<void>;
};

export const useDigitalRegistrationManualStatusUpdate = ({
  registrationId,
  refreshDigitalRegistrationStatus,
}: Args) => {
  const manualStepUpdateMutation = useMutation(
    async (newStatus: RegistrationStatus.PendingCustomerSignature) => {
      const result = await getApiClient().ChangeRegistrationStatus({
        registrationId,
        status: newStatus,
      });

      if (result.changeRegistrationStatus) {
        const timeStart = new Date().getTime();
        const POLLING_TIMEOUT_MAX_SECONDS = 30;
        const ONE_SECOND_IN_MS = 1000;

        const isPollingTimedOut = () =>
          new Date().getTime() - timeStart >
          POLLING_TIMEOUT_MAX_SECONDS * ONE_SECOND_IN_MS;

        while (!isPollingTimedOut()) {
          await new Promise((resolve) => setTimeout(resolve, ONE_SECOND_IN_MS));
          const newStatusResponse =
            await getApiClient().FindDigitalCarRegistrationStatus({
              registrationId,
            });

          if (newStatusResponse.reg?.status === newStatus) {
            break;
          }
        }

        await refreshDigitalRegistrationStatus();
      }
    }
  );

  return {
    isUpdating: manualStepUpdateMutation.isLoading,
    handleUpdate: manualStepUpdateMutation.mutate,
  };
};
