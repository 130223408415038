import { ReactNode, forwardRef } from "react";
import { useQuery } from "react-query";
import { Col, Form, FormItemProps, Input, Select, Row } from "antd";

import { RegistrationType } from "@/constants";
import { CarRegistrationType } from "@/generated/graphql";
import { LicensePlateType } from "@/features/registrationTab/types";
import { LICENSE_PLATE_TYPE_LABELS } from "@/features/registrationTab/constants";

type ValidationRule = NonNullable<FormItemProps["rules"]>[number];

interface VehicleDetailsFormProps {
  isLowEmissionsVehicle: boolean;
  registrationType: CarRegistrationType;
}

interface LicensePlateInputsProps {
  required?: boolean;
  withType: boolean;
  label: ReactNode;
  name: string;
}

interface LicensePlateTypeInputProps {
  label?: ReactNode;
  name: string | string[];
}

const requiredRule: ValidationRule = { required: true };

const licensePlatePinRule = {
  pattern: /^[A-Za-z0-9]{3}$/,
  message: "Must be 3 letters or numbers",
};

const getRegions = async (): Promise<Set<string>> => {
  const { default: list } = await import("./de-regions.json");

  return new Set(list);
};

const useRegionRule = (): ValidationRule => {
  const { data: regions } = useQuery("reg-regions", getRegions, {
    refetchOnMount: false,
    refetchOnWindowFocus: false,
  });

  return {
    message: "Invalid region",
    validator: async (_, value) => {
      if (value && !regions?.has(value)) {
        throw new Error("Invalid region");
      }
    },
  };
};

const LicensePlateTypeInput = ({ name, label }: LicensePlateTypeInputProps) => (
  <Form.Item required label={label} name={name} rules={[requiredRule]}>
    <Select
      placeholder="Plate type"
      allowClear
      options={Object.values(LicensePlateType).map((value) => ({
        value,
        label: LICENSE_PLATE_TYPE_LABELS[value],
      }))}
    />
  </Form.Item>
);

const LicensePlateInputs = ({
  required,
  withType,
  label,
  name,
}: LicensePlateInputsProps) => {
  const regionRule = useRegionRule();
  const licensePlateType = Form.useWatch([name, "licensePlateType"]);

  return (
    <Form.Item required={required} label={label}>
      <Row gutter={16}>
        {withType && (
          <Col span={5}>
            <LicensePlateTypeInput name={[name, "licensePlateType"]} />
          </Col>
        )}
        <Col span={5}>
          <Form.Item
            name={[name, "region"]}
            rules={[{ required }, regionRule]}
            style={{ marginBottom: 0 }}
          >
            <Input placeholder="Region" />
          </Form.Item>
        </Col>
        <Col span={5}>
          <Form.Item
            name={[name, "letters"]}
            rules={[
              { required },
              {
                pattern: /^[A-Z]{1,2}$/,
                message: "Must be [1-2] capital letters",
              },
            ]}
            style={{ marginBottom: 0 }}
          >
            <Input placeholder="Letters" />
          </Form.Item>
        </Col>
        <Col span={5}>
          <Form.Item
            name={[name, "digits"]}
            rules={[
              { required },
              {
                pattern: /^[0-9]{1,4}$/,
                message: "Must be [1-4] digits",
              },
            ]}
            style={{ marginBottom: 0 }}
          >
            <Input placeholder="Digits" />
          </Form.Item>
        </Col>
        {licensePlateType === LicensePlateType.Electric && (
          <Col span={4}>
            <Form.Item style={{ marginBottom: 0 }}>
              <Input value="E" disabled />
            </Form.Item>
          </Col>
        )}
      </Row>
    </Form.Item>
  );
};

export const VehicleDetailsForm = forwardRef<
  null | HTMLDivElement,
  VehicleDetailsFormProps
>(({ isLowEmissionsVehicle, registrationType }, ref) => {
  const isCustomPlate =
    registrationType === RegistrationType.AhDigitalRegistrationWithCustomPlate;

  return (
    <Row gutter={16} ref={ref}>
      {isCustomPlate ? (
        <Col span={24}>
          <Row gutter={16}>
            <Col span={12}>
              <LicensePlateInputs
                required
                withType={isLowEmissionsVehicle}
                label="License Plate"
                name="licensePlate"
              />
            </Col>
            <Col span={8}>
              <Form.Item
                required
                label="License Plate Pin"
                name="licensePlatePin"
                rules={[requiredRule, licensePlatePinRule]}
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>
        </Col>
      ) : isLowEmissionsVehicle ? (
        <Col span={24}>
          <Row gutter={16}>
            <Col span={6}>
              <LicensePlateTypeInput
                label="License Plate Type"
                name={["licensePlate", "licensePlateType"]}
              />
            </Col>
          </Row>
        </Col>
      ) : null}
      <Col span={12}>
        <LicensePlateInputs
          required
          withType={isLowEmissionsVehicle}
          label="Old License Plate"
          name="oldLicensePlate"
        />
      </Col>
      <Col span={24}>
        <Row gutter={16}>
          <Col span={8}>
            <Form.Item
              required
              label="Reg. Certificate I Code"
              name="registrationCertificate1Code"
              rules={[requiredRule]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              required
              label="Reg. Certificate I Code security code"
              name="registrationCertificate1CodeSecurityCode"
              rules={[requiredRule]}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>
      </Col>
      <Col span={24}>
        <Row gutter={16}>
          <Col span={8}>
            <Form.Item
              required
              label="Reg. Certificate II Number"
              name="registrationCertificate2Number"
              rules={[requiredRule]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              required
              label="Reg. Certificate II Number security code"
              name="registrationCertificate2NumberSecurityCode"
              rules={[requiredRule]}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>
      </Col>
      <Col span={8}>
        <Form.Item
          required
          label="EVB Number"
          name="evbNumber"
          rules={[
            requiredRule,
            {
              pattern: /^([A-Za-z0-9])*$/,
              message: "Only letters and numbers allowed",
            },
          ]}
        >
          <Input />
        </Form.Item>
      </Col>
    </Row>
  );
});
