import { Params, generatePath } from "react-router-dom";

import {
  MFE_ROOT_PATH,
  RoutePath,
  RegistrationStatus,
  registrationStatusLabels,
} from "@/constants";

export const getPathLink = (path: RoutePath, params?: Params) => {
  const preparedPath = path.startsWith("/") ? path : `/${path}`;

  return generatePath(MFE_ROOT_PATH + preparedPath, {
    lang: "en",
    ...params,
  });
};

export const humanizeRegistrationStatus = (
  status: RegistrationStatus
): string => registrationStatusLabels[status] ?? status;

export * from "./triggerDownload";
