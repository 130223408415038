import { useState } from "react";
import { Col, ColProps, Row, Skeleton, Space } from "antd";

import {
  DigitalCarRegEditableDetails,
  LicensePlateType,
} from "@/features/registrationTab/types";

import { DigitalCarReg } from "../../types";
import { Section } from "../Section";

import {
  BankDetailsContent,
  HolderDetailsContent,
  VehicleDetailsContent,
} from "./details";
import { DetailsForm } from "./DetailsForm";
import { ActionButtons } from "./ActionButtons";

interface RegistrationOverviewProps {
  registration: DigitalCarReg;
  onRefetch: () => Promise<void>;
  onUpdate: (details: DigitalCarRegEditableDetails) => Promise<void>;
}

const commonColProps: ColProps = {
  span: 12,
  style: { marginBottom: "8px" },
};

export const DigitalRegistrationDataOverview = ({
  registration,
  onRefetch,
  onUpdate,
}: RegistrationOverviewProps) => {
  const [showDetailsForm, setShowDetailsForm] = useState(false);
  const isLowEmissionsVehicle = Boolean(registration.lowEmissionVehicle);
  const isElectricPlateType =
    registration.lowEmissionVehicle &&
    registration.licensePlate?.licensePlateType === LicensePlateType.Electric;

  const handleDetailsUpdate = async (details: DigitalCarRegEditableDetails) => {
    await onUpdate(details);
    setShowDetailsForm(false);
  };

  const skeleton = <Skeleton active paragraph={{ rows: 6 }} />;

  return (
    <Space direction="vertical" style={{ width: "100%" }}>
      <Section
        title="Registration Overview"
        dataQaId="section-registration-overview"
      >
        <Row gutter={16}>
          <Col {...commonColProps}>
            {registration ? (
              <VehicleDetailsContent registration={registration} />
            ) : (
              skeleton
            )}
          </Col>
          <Col {...commonColProps}>
            {registration ? (
              <HolderDetailsContent registration={registration} />
            ) : (
              skeleton
            )}
          </Col>
          {!isElectricPlateType && (
            <Col {...commonColProps}>
              {registration ? (
                <BankDetailsContent registration={registration} />
              ) : (
                skeleton
              )}
            </Col>
          )}
          <Col
            {...commonColProps}
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <ActionButtons
              disableUpdateButton={showDetailsForm}
              registrationId={registration.id}
              registrationStatus={registration.status}
              onRefetch={onRefetch}
              onUpdateRequest={() => setShowDetailsForm(true)}
            />
          </Col>
        </Row>
      </Section>
      {showDetailsForm && (
        <Section title="Edit details" dataQaId="edit-details">
          <DetailsForm
            isLowEmissionsVehicle={isLowEmissionsVehicle}
            registration={registration}
            onCancel={() => setShowDetailsForm(false)}
            onSubmit={handleDetailsUpdate}
          />
        </Section>
      )}
    </Space>
  );
};
