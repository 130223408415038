import { Button, Col, Descriptions, Row, Skeleton } from "antd";
import formatDate from "date-fns/format";

import { Salutation, salutationLabels } from "@/constants";

import { Address, Customer } from "./types";

interface OwnerDetailsProps {
  isLoading?: boolean;
  primary?: null | Customer;
  secondary?: null | Customer;
  address?: null | Address;
  onFormShow?: () => void;
}

const DESC_ITEM_PROPS = {
  labelStyle: { fontWeight: 600 },
  contentStyle: { paddingRight: 24 },
};

const formatDateOfBirth = (date: string) =>
  formatDate(new Date(date), "dd.MM.yyyy");

const formatName = (
  customer: Pick<Customer, "salutation" | "firstName" | "lastName">
): null | string =>
  [
    salutationLabels[customer.salutation as Salutation],
    customer.firstName,
    customer.lastName,
  ]
    .filter(Boolean)
    .join(" ") || null;

const formatAddress = (address: Address): null | string => {
  const withoutCountry =
    [address.houseNumber, address.street, address.zipcode, address.city]
      .filter(Boolean)
      .join(", ") || null;

  return withoutCountry && `${withoutCountry}, France`;
};

export const OwnerDetails = ({
  isLoading,
  primary,
  secondary,
  address,
  onFormShow,
}: OwnerDetailsProps) => {
  const isMissingData = !(primary && address);

  return (
    <>
      {isLoading ? (
        <Skeleton active />
      ) : (
        <div data-qa-selector="owner-details-section">
          <Descriptions column={{ xs: 1, sm: 1, md: 2 }}>
            <Descriptions.Item
              className="qa-selector-name"
              label="Owner Name"
              {...DESC_ITEM_PROPS}
            >
              {primary && formatName(primary)}
            </Descriptions.Item>
            <Descriptions.Item
              className="qa-selector-city-of-birth"
              label="City of Birth"
              {...DESC_ITEM_PROPS}
            >
              {primary?.cityOfBirth}
            </Descriptions.Item>
            <Descriptions.Item
              className="qa-selector-date-of-birth"
              label="Date of Birth"
              {...DESC_ITEM_PROPS}
            >
              {primary?.dateOfBirth && formatDateOfBirth(primary.dateOfBirth)}
            </Descriptions.Item>
            <Descriptions.Item
              className="qa-selector-department-of-birth"
              label="Department of Birth"
              {...DESC_ITEM_PROPS}
            >
              {primary?.departmentOfBirth}
            </Descriptions.Item>
            <Descriptions.Item
              className="qa-selector-registration-address"
              label="Registration Address"
              {...DESC_ITEM_PROPS}
            >
              {address && formatAddress(address)}
            </Descriptions.Item>
            <Descriptions.Item
              className="qa-selector-co-owner"
              label="Co-owner"
              {...DESC_ITEM_PROPS}
            >
              {secondary
                ? [
                    formatName(secondary),
                    secondary.dateOfBirth &&
                      formatDateOfBirth(secondary.dateOfBirth),
                  ]
                    .filter(Boolean)
                    .join(" ")
                : "-"}
            </Descriptions.Item>
          </Descriptions>
        </div>
      )}
      <Row justify="end">
        <Col>
          <Button
            ghost
            type="primary"
            disabled={isMissingData}
            onClick={onFormShow}
          >
            Update Details
          </Button>
        </Col>
      </Row>
    </>
  );
};
