import { useMutation, useQuery } from "react-query";

import { useHasPermission } from "@/acl";
import { runQuery, runQueryWithRecovery } from "@/apiClient";
import {
  Assignee,
  DigitalCarReg,
  DigitalCarRegEditableDetails,
  LicensePlateType,
} from "@/features/registrationTab/types";

type Address = Record<typeof ADDRESS_FIELDS[number], string | null>;

const ADDRESS_FIELDS = ["city", "street", "houseNumber", "zipcode"] as const;

const isAddressChanged = (left: Address, right: Address) =>
  ADDRESS_FIELDS.some((fieldName) => left[fieldName] !== right[fieldName]);

const assignUser = async ({
  digitalCarRegId,
  userId,
}: {
  digitalCarRegId: string;
  userId: null | string;
}) => {
  await runQuery("UpdateDigitalRegistrationsDetails", {
    variables: {
      id: digitalCarRegId,
      input: { assignedTo: userId },
    },
  });
};

const updateDigitalCarRegData = async ({
  addressId,
  registrationId,
  shouldUpdateAddress,
  shouldUpdateBankData,
  data,
}: {
  addressId: string | null;
  registrationId: string;
  shouldUpdateAddress: boolean;
  shouldUpdateBankData: boolean;
  data: DigitalCarRegEditableDetails;
}) => {
  const { address, bankAccount, licensePlate, oldLicensePlate, ...restValues } =
    data;

  await Promise.all([
    shouldUpdateAddress &&
      address &&
      runQuery("UpdateCarRegistrationAddress", {
        variables: {
          addressId: addressId,
          ...address,
        },
      }),

    shouldUpdateBankData &&
      bankAccount?.details &&
      runQuery("UpdateBankData", {
        variables: {
          id: registrationId,
          bankAccount: bankAccount.details,
        },
      }),
  ]);

  await runQuery("UpdateDigitalRegistrationsDetails", {
    variables: {
      id: registrationId,
      input: {
        ...restValues,
        licensePlate: {
          ...licensePlate,
          licensePlateType:
            licensePlate?.licensePlateType ?? LicensePlateType.Standard,
        },
        oldLicensePlate: {
          ...oldLicensePlate,
          licensePlateType:
            oldLicensePlate?.licensePlateType ?? LicensePlateType.Standard,
        },
      },
    },
  });
};

export const useDigitalCarReg = ({
  orderId,
  isEnabled,
  onError,
}: {
  isEnabled: boolean;
  orderId: string;
  onError: (msg: string) => void;
}) => {
  const hasPermission = useHasPermission();
  const digitalCarRegKey = ["digitalCarReg", orderId];
  const {
    isLoading,
    data: { assignees, data, youSignRequest } = {},
    refetch: refetchQuery,
  } = useQuery(
    digitalCarRegKey,
    () =>
      runQueryWithRecovery("FindDigitalCarRegData", {
        variables: {
          orderId,
          withAssignees: hasPermission("manageDigitalCarRegAssignee"),
        },
        recoverOnClientError: (res) => res.data,
      }),
    {
      enabled: isEnabled,
      refetchOnWindowFocus: false,

      select: (data) => ({
        data: data?.digitalCarReg as undefined | DigitalCarReg,
        assignees: data?.assignees?.entities as undefined | Assignee[],
        youSignRequest: data?.youSignRequest?.entities?.[0],
      }),
    }
  );

  const refetch = async () => {
    await refetchQuery();
  };

  const assignUserMutation = useMutation(assignUser, {
    onSuccess: refetch,
  });
  const updateDataMutation = useMutation(updateDigitalCarRegData, {
    onSuccess: refetch,
    onError: () => onError("Update failed"),
  });

  const handleUserAssign = (userId: string | undefined) =>
    assignUserMutation.mutate({
      digitalCarRegId: data!.id,
      userId: userId ?? null,
    });

  const handleDetailsUpdate = async (details: DigitalCarRegEditableDetails) => {
    const currentRegistration = data!;

    await updateDataMutation.mutateAsync({
      addressId: currentRegistration.address?.id,
      registrationId: currentRegistration.id,
      shouldUpdateAddress:
        details.address != null &&
        currentRegistration.address != null &&
        isAddressChanged(currentRegistration.address, details.address),
      shouldUpdateBankData: hasPermission("viewEditBankData"),
      data: details,
    });
  };

  return {
    isLoading,
    isAssigningUser: assignUserMutation.isLoading,
    assignees,
    data,
    youSignRequest,
    refetch,
    handleUserAssign,
    handleDetailsUpdate,
  };
};
