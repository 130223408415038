import { ReactNode } from "react";
import { useParams } from "react-router-dom";
import {
  Button,
  ButtonProps,
  Col,
  Descriptions,
  Divider,
  Row,
  Space,
  Tooltip,
  Typography,
} from "antd";

import { RegistrationStatus } from "@/constants";
import { triggerDownload } from "@/utils";
import { KbaRequestStatus } from "@/features/registrationQaManagement/types";

import {
  ChangeStatusByType,
  getKbaRequestLink,
  useRegManagement,
  useStatusChanges,
} from "./hooks";
import { KbaRequestList } from "./KbaRequestList";
import { RegForm } from "./RegForm";

interface SectionProps {
  title: ReactNode;
  children: ReactNode;
}

interface ChangeStatusButtonProps extends ButtonProps {
  statusChanges: ReturnType<typeof useStatusChanges>;
  changeTo: ChangeStatusByType;
}

const Section = ({ title, children }: SectionProps) => (
  <div style={{ marginBottom: 48 }}>
    <Divider orientation="left">
      <Typography.Title italic type="secondary" level={3}>
        {title}
      </Typography.Title>
    </Divider>
    {children}
  </div>
);

const ChangeStatusButton = ({
  statusChanges,
  changeTo,
  ...buttonProps
}: ChangeStatusButtonProps) => (
  <Button
    {...buttonProps}
    loading={statusChanges.isChangingFor(changeTo)}
    onClick={() => statusChanges.change(changeTo)}
  />
);

export const RegistrationQaManagement = () => {
  const registrationId = useParams().registrationId!;
  const statusChanges = useStatusChanges({ registrationId });
  const {
    isLoading,
    digitalReg,
    qaRegDetails,
    qaVariants = [],
    signatureRequest,
    kbaRequests,
    putDigitalReg,
  } = useRegManagement({
    registrationId,
  });
  const isSignatureUrlUnavailable = !isLoading && !signatureRequest?.url;

  return (
    <Row justify="center" style={{ padding: "24px 0" }}>
      <Col xs={22} lg={18} xl={15}>
        <Typography.Title level={3} style={{ marginBottom: 20 }}>
          DIGITAL REGISTRATION MANAGEMENT
        </Typography.Title>

        <Section title="Overview">
          <Descriptions contentStyle={{ fontWeight: "bold", color: "gray" }}>
            <Descriptions.Item label="Order number">
              {digitalReg?.retailOrder ? (
                <a
                  target="_blank"
                  href={`/en/orders/${digitalReg.retailOrder.id}`}
                >
                  {digitalReg.retailOrder.orderNumber}
                </a>
              ) : (
                "-"
              )}
            </Descriptions.Item>
            <Descriptions.Item label="Payment type">
              {digitalReg?.retailOrder?.paymentType ?? "-"}
            </Descriptions.Item>
            <Descriptions.Item label="Order state">
              {digitalReg?.retailOrder?.state?.toUpperCase() ?? "-"}
            </Descriptions.Item>
            <Descriptions.Item label="Registration state">
              {digitalReg?.retailOrder ? (
                <a
                  target="_blank"
                  href={`/en/orders/${digitalReg.retailOrder.id}/registrations`}
                >
                  {digitalReg.status}
                </a>
              ) : (
                "-"
              )}
            </Descriptions.Item>
          </Descriptions>
        </Section>

        <Section title="YouSign">
          <Space wrap>
            <Tooltip
              title={
                isSignatureUrlUnavailable
                  ? "The link may not be available yet"
                  : null
              }
            >
              <Button
                {...(signatureRequest?.url && { href: signatureRequest.url })}
                target="_blank"
                rel="noopener noreferrer"
                disabled={isSignatureUrlUnavailable}
              >
                Open YouSign link
              </Button>
            </Tooltip>
            <ChangeStatusButton
              statusChanges={statusChanges}
              changeTo={{
                registrationStatus: RegistrationStatus.PendingCustomerSignature,
              }}
            >
              Regenerate and resend documents
            </ChangeStatusButton>
            {signatureRequest?.status === "ONGOING" && (
              <>
                <ChangeStatusButton
                  statusChanges={statusChanges}
                  changeTo={{
                    signatureStatus: "DONE",
                  }}
                >
                  Mark as Done
                </ChangeStatusButton>
                <ChangeStatusButton
                  statusChanges={statusChanges}
                  changeTo={{
                    signatureStatus: "EXPIRED",
                  }}
                >
                  Mark as Expired
                </ChangeStatusButton>
              </>
            )}
          </Space>
        </Section>

        <Section title="KBA">
          <Space wrap style={{ marginBottom: 16 }}>
            <ChangeStatusButton
              statusChanges={statusChanges}
              changeTo={{
                registrationStatus: RegistrationStatus.SubmitRequestToKba,
              }}
            >
              Send request to KBA
            </ChangeStatusButton>
            {digitalReg?.status === RegistrationStatus.SubmitRequestToKba &&
              (
                [
                  [KbaRequestStatus.Successful, "Registration successful"],
                  [KbaRequestStatus.Failed, "Registration failed"],
                  [KbaRequestStatus.Approved, "Sabrina approved"],
                  [KbaRequestStatus.Rejected, "Sabrina rejected"],
                ] as const
              ).map(([kbaStatus, label]) => (
                <ChangeStatusButton
                  key={kbaStatus}
                  statusChanges={statusChanges}
                  changeTo={{ kbaStatus }}
                >
                  {label}
                </ChangeStatusButton>
              ))}
          </Space>
          <KbaRequestList
            isLoading={isLoading}
            items={kbaRequests}
            onDownload={(requestId) =>
              getKbaRequestLink(requestId).then((url) => {
                triggerDownload({ url });
              })
            }
          />
        </Section>

        <Section title="Update registration">
          <RegForm
            key={qaRegDetails?.updatedOn}
            initialValues={qaRegDetails}
            qaVariants={qaVariants}
            onSubmit={putDigitalReg}
          />
        </Section>
      </Col>
    </Row>
  );
};
