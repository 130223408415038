import { CSSProperties } from "react";
import { Row, Col, Form, Input, Select, DatePicker, Button } from "antd";
import SearchOutlined from "@ant-design/icons/SearchOutlined";

import { RegistrationStatus } from "@/constants";
import { humanizeRegistrationStatus } from "@/utils";

import { RegFilters } from "../types";
import { useAssigneeList } from "../hooks/useAssigneeList";

interface SearchBarProps {
  style?: CSSProperties;
  onFiltersChange?: (values: RegFilters) => void;
}

export const SearchBar = ({ style, onFiltersChange }: SearchBarProps) => {
  const { list: assignees } = useAssigneeList();

  return (
    <Form<RegFilters>
      layout="vertical"
      style={style}
      onFinish={onFiltersChange}
    >
      <Row align="bottom" gutter={24}>
        <Col xs={24} sm={12} lg={5}>
          <Form.Item name="orderNumber" label="Order No.">
            <Input placeholder="Type order number" />
          </Form.Item>
        </Col>
        <Col xs={24} sm={12} lg={5}>
          <Form.Item name="stockNumber" label="Stock No.">
            <Input placeholder="Type stock number" />
          </Form.Item>
        </Col>
        <Col xs={24} sm={12} lg={5}>
          <Form.Item name="assignedTo" label="Assigned to">
            <Select
              showSearch
              allowClear
              placeholder="Search assignee"
              optionFilterProp="children"
              options={assignees.map((assignee) => ({
                value: assignee.id,
                label: `${assignee.firstName} ${assignee.lastName}`,
              }))}
            />
          </Form.Item>
        </Col>
        <Col xs={24} sm={12} lg={5}>
          <Form.Item name="status" label="Registration status">
            <Select
              allowClear
              placeholder="Select status"
              options={Object.values(RegistrationStatus).map((it) => ({
                value: it,
                label: humanizeRegistrationStatus(it),
              }))}
            />
          </Form.Item>
        </Col>
        <Col xs={24} sm={12} lg={5}>
          <Form.Item name="handoverDate" label="Handover date">
            <DatePicker.RangePicker style={{ width: "100%" }} />
          </Form.Item>
        </Col>
        <Col xs={24} sm={12} lg={5}>
          <Form.Item name="hasHandoverDate" label="Handover date set">
            <Select
              allowClear
              placeholder="Yes/No"
              options={[
                { value: "YES", label: "Yes" },
                { value: "NO", label: "No" },
              ]}
            />
          </Form.Item>
        </Col>
        <Col xs={24} sm={9} lg={4}>
          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              icon={<SearchOutlined />}
              style={{ width: 46 }}
            />
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};
