import { useQuery } from "react-query";

import { getApiClient } from "@/apiClient";

import { Assignee } from "../types";

export const useAssigneeList = () => {
  const { data: { result } = {} } = useQuery(
    ["assignees"],
    () => getApiClient().ListAssignees(),
    {
      refetchOnMount: false,
      refetchOnWindowFocus: false,
    }
  );

  return {
    list: (result?.entities ?? []) as Assignee[],
  };
};
