import addDays from "date-fns/addDays";
import startOfDay from "date-fns/startOfDay";

import { RegSearch } from "../../types";

interface MakeRegRequestSearchInput extends Omit<RegSearch, "orderNumber"> {
  now: Date;
  orderId?: string; // orderNumber -> orderId
}

export const PAGE_SIZE = 16;

const startOfNextDay = (date: Date) => startOfDay(addDays(date, 1));

const makeFilters = ({
  now,
  sort,
  handoverDate,
  hasHandoverDate,
  ...filters
}: MakeRegRequestSearchInput) => {
  const simpleFilters = (
    ["orderId", "stockNumber", "status", "assignedTo"] as const
  )
    .filter((field) => filters[field])
    .map((field) => ({
      field,
      op: "eq",
      value: filters[field],
    }));

  const handoverDateFilters = [
    ...(handoverDate
      ? [
          {
            field: "handoverDate",
            op: "ge",
            value: startOfDay(handoverDate[0].toDate()).toISOString(),
          },
          {
            field: "handoverDate",
            op: "lt", // Exclusive
            value: startOfNextDay(handoverDate[1].toDate()).toISOString(),
          },
        ]
      : []),

    ...(hasHandoverDate
      ? [
          {
            field: "handoverDate",
            op: hasHandoverDate === "YES" ? "is_not_null" : "is_null",
          },
        ]
      : []),

    // If sorting by "Days until handover" is specified, we need to filter out items
    // that have a handover date in the past to present only those that have "Days until handover"
    // greater than or equal to 0 (i.e. in the future).
    ...(!handoverDate && !hasHandoverDate && sort?.[0] === "daysUntilHandover"
      ? [
          {
            field: "handoverDate",
            op: "ge",
            value: now.toISOString(),
          },
        ]
      : []),
  ];

  return [...simpleFilters, ...handoverDateFilters];
};

const makeSorting = ({ sort }: MakeRegRequestSearchInput) => {
  if (!sort) {
    return [
      {
        property: "createdOn",
        direction: "DESC",
      },
    ];
  }

  const [field, order] = sort;

  return [
    {
      property: field === "daysUntilHandover" ? "handoverDate" : field,
      direction: order === "descend" ? "DESC" : "ASC",
    },
  ];
};

export const makeRegRequestSearch = (input: MakeRegRequestSearchInput) => ({
  page: input.page - 1,
  pageSize: PAGE_SIZE,
  sorts: makeSorting(input),

  filter: (() => {
    const filters = makeFilters(input);

    return filters.length > 1
      ? {
          field: null,
          op: "and",
          value: filters,
        }
      : filters[0] ?? null;
  })(),
});
