import { useQuery } from "react-query";
import { COUNTRIES } from "@retail/i18n/constants";

import { CustomerType, RegistrationType } from "@/constants";
import { AccessDomain } from "@/acl";
import { getApiClient } from "@/apiClient";

import { OrderDetails, RegAction } from "../types";

export const useCarRegistration = (orderId: string) => {
  const carReg = useQuery(
    ["carReg", orderId],
    () =>
      getApiClient({
        domain: AccessDomain.RegistrationsOrderManagement,
      }).FindCarRegistrationData({
        orderId,
      }),
    { refetchOnWindowFocus: false }
  );
  const orderCustomer = useQuery(
    ["orderCustomer", orderId],
    () =>
      getApiClient({
        domain: AccessDomain.OrderManagement,
      }).FindOrderRetailCustomer({
        orderId,
        customerType: CustomerType.Primary,
      }),
    { refetchOnWindowFocus: false }
  );
  const regOrderItems = carReg.data?.orderDetails?.carRegistrationItems ?? [];
  const isDigitalReg = regOrderItems.some((item) =>
    [
      RegistrationType.AhDigitalRegistration,
      RegistrationType.AhDigitalRegistrationWithCustomPlate,
    ].includes(item!.carRegistration!.registrationType! as RegistrationType)
  );
  const isAhRegistration = regOrderItems.some(
    (it) =>
      it?.carRegistration?.registrationType === RegistrationType.AhRegistration
  );
  const showVehiclePreparationDetails =
    isAhRegistration &&
    carReg.data?.orderDetails?.retailCountry === COUNTRIES.FR;

  const handleRegActionDone = async (action: string) => {
    await getApiClient({
      domain: AccessDomain.RegistrationsOrderManagement,
    }).ApplyRegAction({
      orderId,
      action,
    });
    await carReg.refetch();
  };

  return {
    data: carReg.data && {
      ...(carReg.data as
        | undefined
        | { regActions: RegAction[]; orderDetails: OrderDetails }),
    },
    isDigitalReg,
    showVehiclePreparationDetails,
    isCarRegLoading: carReg.isLoading,
    handleRegActionDone,
  };
};
