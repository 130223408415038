import { Descriptions, Select, Skeleton } from "antd";
import { useMemo } from "react";

import { CarRegistrationType } from "@/generated/graphql";
import { Nil } from "@/types";
import { humanizeRegistrationStatus } from "@/utils";
import { useHasPermission } from "@/acl";
import { RegistrationType } from "@/constants";

import { DigitalCarReg, Assignee } from "../../types";

type UserAssignHandler = (id: undefined | string) => void;

interface DigitalRegistrationOverviewProps {
  isAssigningUser?: boolean;
  assignees: Nil | Assignee[];
  digitalCarReg: Nil | DigitalCarReg;
  onUserAssign: UserAssignHandler;
}

const qaId = (label: string) => `QA_ID_${label}`;

const humanizeRegistrationType = (type: CarRegistrationType) => {
  const mapping: Partial<Record<CarRegistrationType, string>> = {
    [RegistrationType.AhDigitalRegistration]: "Autohero Registration",
    [RegistrationType.AhDigitalRegistrationWithCustomPlate]:
      "Autohero Registration with custom plates",
  };

  return mapping[type] ?? type;
};

const formatAssignee = (assignee: Assignee): undefined | string =>
  [assignee.firstName, assignee.lastName].filter(Boolean).join(" ") ||
  undefined;

const makeAssigneeOptions = (assignees: Assignee[]) =>
  assignees.reduce<Record<"value" | "label", string>[]>((acc, assignee) => {
    const formatted = formatAssignee(assignee);

    if (formatted) {
      acc.push({
        value: assignee.id,
        label: formatted,
      });
    }

    return acc;
  }, []);

export const DigitalRegistrationOverview = ({
  isAssigningUser,
  assignees,
  digitalCarReg,
  onUserAssign,
}: DigitalRegistrationOverviewProps) => {
  const hasPermission = useHasPermission();
  const assigneeOptions = useMemo(
    () => makeAssigneeOptions(assignees ?? []),
    [assignees]
  );

  if (!digitalCarReg) {
    return <Skeleton />;
  }

  const assignedUserId = digitalCarReg.assignedToUser?.id;
  const withBottomPadding = { paddingBottom: "4px" };
  const canManageAssignee = hasPermission("manageDigitalCarRegAssignee");

  const handleUserAssign: UserAssignHandler = (id) => {
    if (!id && !assignedUserId) {
      return;
    }

    onUserAssign(id);
  };

  return (
    <Descriptions column={1}>
      <Descriptions.Item
        className={qaId("registration_product")}
        label="Registration product"
        style={withBottomPadding}
      >
        {humanizeRegistrationType(digitalCarReg.registrationType)}
      </Descriptions.Item>
      <Descriptions.Item
        className={qaId("registration_status")}
        label="Registration status"
        style={withBottomPadding}
      >
        {humanizeRegistrationStatus(digitalCarReg.status)}
      </Descriptions.Item>
      <Descriptions.Item
        className={qaId("assigned_to")}
        label="Assigned to"
        labelStyle={{
          display: "flex",
          alignItems: "center",
        }}
      >
        {canManageAssignee && (
          <Select
            showSearch
            allowClear
            placeholder="Search Assignee"
            optionFilterProp="label"
            disabled={isAssigningUser}
            loading={isAssigningUser}
            value={assignedUserId ?? ""}
            options={assigneeOptions}
            style={{ width: 260 }}
            onChange={handleUserAssign}
          />
        )}
        {!canManageAssignee &&
          (digitalCarReg.assignedToUser
            ? formatAssignee(digitalCarReg.assignedToUser)
            : "-")}
      </Descriptions.Item>
    </Descriptions>
  );
};
