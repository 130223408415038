import { Col, Form, Input, Row, Typography, ColProps, InputNumber } from "antd";

const colProps: ColProps = { span: 8 };
const requiredRule = { required: true };

export const RegistrationAddressForm = () => (
  <>
    <Typography.Title level={5}>Registration Address</Typography.Title>
    <Row gutter={16}>
      <Col {...colProps}>
        <Form.Item
          required
          label="Street Name"
          name={["address", "street"]}
          rules={[requiredRule]}
        >
          <Input />
        </Form.Item>
      </Col>
      <Col {...colProps}>
        <Form.Item
          required
          label="House Number"
          name={["address", "houseNumber"]}
          rules={[requiredRule]}
        >
          <Input />
        </Form.Item>
      </Col>
      <Col {...colProps}>
        <Form.Item
          required
          label="City"
          name={["address", "city"]}
          rules={[requiredRule]}
        >
          <Input />
        </Form.Item>
      </Col>
      <Col {...colProps}>
        <Form.Item
          required
          label="Zipcode"
          name={["address", "zipcode"]}
          rules={[requiredRule]}
        >
          <InputNumber controls={false} />
        </Form.Item>
      </Col>
    </Row>
    <Typography.Title level={5}>Holder Place of Birth</Typography.Title>
    <Col {...colProps}>
      <Form.Item
        required
        label="City"
        name="holderPlaceOfBirth"
        rules={[requiredRule]}
      >
        <Input />
      </Form.Item>
    </Col>
  </>
);
