import { BrowserRouter, Routes, Route } from "react-router-dom";

import { createDevModeRoute } from "@/features/registrationTab/devMode";
import { MFE_ROOT_PATH, RoutePath } from "@/constants";
import { RegistrationOverview } from "@/features/registrationOverview";
import { RegistrationQaManagement } from "@/features/registrationQaManagement";

import { RootContextProvider } from "./RootContextProvider";

export const App = () => (
  <RootContextProvider>
    <BrowserRouter>
      <Routes>
        <Route path={MFE_ROOT_PATH}>
          <Route
            path={RoutePath.RegistrationOverview}
            element={<RegistrationOverview />}
          />
          <Route
            path={RoutePath.RegistrationQaManagement}
            element={<RegistrationQaManagement />}
          />
        </Route>
        {__WEBPACK_DEV_SERVER__ && createDevModeRoute()}
      </Routes>
    </BrowserRouter>
  </RootContextProvider>
);
