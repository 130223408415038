import { notification, Space } from "antd";

import { RegistrationStatus } from "@/constants";
import { useHasPermission } from "@/acl";

import { useCarRegistration } from "./hooks/useCarRegistration";
import { useDigitalCarReg } from "./hooks/useDigitalCarReg";
import { Section } from "./components/Section";
import { RegistrationActions } from "./components/RegistrationActions";
import { DigitalRegistrationOverview } from "./components/DigitalRegistrationOverview";
import { DigitalRegistrationStatusOverview } from "./components/DigitalRegistrationStatusOverview";
import { DigitalRegistrationDataOverview } from "./components/DigitalRegistrationDataOverview";
import { VehiclePreparationDetails } from "./components/VehiclePreparationDetails";

interface RegistrationTabProps {
  orderId: string;
}

export const RegistrationTab = ({ orderId }: RegistrationTabProps) => {
  const [notificationApi, notificationContextHolder] =
    notification.useNotification();
  const hasPermission = useHasPermission();
  const {
    data,
    isDigitalReg,
    showVehiclePreparationDetails,
    isCarRegLoading,
    handleRegActionDone,
  } = useCarRegistration(orderId);

  const allowDigitalCarRegView = hasPermission("viewDigitalRegistration");
  const digitalCarReg = useDigitalCarReg({
    orderId,
    isEnabled: isDigitalReg && allowDigitalCarRegView,
    onError: (message: string) =>
      notificationApi.error({
        message,
      }),
  });

  return (
    <Space direction="vertical" style={{ width: "100%" }}>
      {isDigitalReg && allowDigitalCarRegView && (
        <>
          <Section title="Overview" dataQaId="section-overview">
            <DigitalRegistrationOverview
              isAssigningUser={digitalCarReg.isAssigningUser}
              assignees={digitalCarReg.assignees}
              digitalCarReg={digitalCarReg.data}
              onUserAssign={digitalCarReg.handleUserAssign}
            />
          </Section>
          <Section
            title="Registration process overview"
            dataQaId="section-status-overview"
          >
            <DigitalRegistrationStatusOverview
              data={digitalCarReg.data}
              documentsSignedAt={digitalCarReg.youSignRequest?.signedOn}
            />
          </Section>
          {digitalCarReg?.data &&
            digitalCarReg.data.status !==
              RegistrationStatus.PendingOnRegistration && (
              <DigitalRegistrationDataOverview
                registration={digitalCarReg.data}
                onRefetch={digitalCarReg.refetch}
                onUpdate={digitalCarReg.handleDetailsUpdate}
              />
            )}
        </>
      )}
      {!isDigitalReg && (
        <>
          <RegistrationActions
            isLoading={isCarRegLoading}
            orderDetails={data?.orderDetails}
            actions={data?.regActions}
            onDone={handleRegActionDone}
          />
          {showVehiclePreparationDetails && (
            <VehiclePreparationDetails orderId={orderId} />
          )}
        </>
      )}
      {notificationContextHolder}
    </Space>
  );
};
