import { Col, ColProps, Form, FormItemProps, Input, Row, Select } from "antd";
import { extractBIC, extractIBAN } from "ibantools";
import { COUNTRIES } from "@retail/i18n/constants";

import {
  TaxCollectionFrequency,
  taxCollectionFrequencyLabels,
} from "@/constants";
import { useHasPermission } from "@/acl";

type ValidationRule = NonNullable<FormItemProps["rules"]>[number];

const commonColProps: ColProps = {
  span: 8,
};

const requiredRule: ValidationRule = { required: true };

const accountHolderRule: ValidationRule = {
  pattern: /(\p{Alpha}| )+/u,
  message: "Letters only",
};

const makeBankFieldRule = (type: "IBAN" | "BIC"): ValidationRule => ({
  validator: async (_: unknown, value: string) => {
    const extract = type === "IBAN" ? extractIBAN : extractBIC;
    const { valid, countryCode } = extract(value);

    if (!valid || countryCode !== COUNTRIES.DE) {
      throw new Error(`Must be valid DE ${type}`);
    }
  },
});

export const DirectDebitForm = () => {
  const hasPermission = useHasPermission();

  return (
    <Row gutter={16}>
      {hasPermission("viewEditBankData") && (
        <>
          <Col {...commonColProps}>
            <Form.Item
              required
              label="IBAN"
              name={["bankAccount", "iban"]}
              rules={[makeBankFieldRule("IBAN")]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col {...commonColProps}>
            <Form.Item
              required
              label="BIC"
              name={["bankAccount", "bic"]}
              rules={[makeBankFieldRule("BIC")]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col {...commonColProps}>
            <Form.Item
              required
              label="Bank name"
              name={["bankAccount", "bankName"]}
              rules={[requiredRule]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col {...commonColProps}>
            <Form.Item
              required
              label="Account holder"
              name={["bankAccount", "holder"]}
              rules={[requiredRule, accountHolderRule]}
            >
              <Input />
            </Form.Item>
          </Col>
        </>
      )}
      <Col {...commonColProps}>
        <Form.Item
          required
          label="Tax collection frequency"
          name="vehicleTaxesCollectedPeriod"
          rules={[requiredRule]}
        >
          <Select
            options={Object.values(TaxCollectionFrequency).map((value) => ({
              value,
              label: taxCollectionFrequencyLabels[value],
            }))}
          />
        </Form.Item>
      </Col>
    </Row>
  );
};
