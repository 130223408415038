import { FetchCustomerDetailsQuery } from "@/generated/graphql";
import { ItemFromNullableList } from "@/types";

export enum CustomerType {
  Primary = "PRIMARY",
  Secondary = "SECONDARY",
}

export type Customer = ItemFromNullableList<
  NonNullable<
    NonNullable<FetchCustomerDetailsQuery["customersByOrder"]>[number]
  >["customers"]
>;

export type OrderAddress = ItemFromNullableList<
  NonNullable<FetchCustomerDetailsQuery["addresses"]>
>;

export type Address = Pick<
  NonNullable<OrderAddress["address"]>,
  "houseNumber" | "street" | "city" | "zipcode"
>;

export interface OwnerFormSubmitted {
  primary: Pick<
    Customer,
    | "firstName"
    | "lastName"
    | "cityOfBirth"
    | "departmentOfBirth"
    | "dateOfBirth"
  >;
  secondary: null | Pick<
    Customer,
    "salutation" | "firstName" | "lastName" | "dateOfBirth"
  >;
  address: Address;
}
