import { ReactNode, Fragment } from "react";
import { Descriptions, DescriptionsProps } from "antd";

import {
  RegistrationType,
  TaxCollectionFrequency,
  taxCollectionFrequencyLabels,
} from "@/constants";
import { CarRegistrationType } from "@/generated/graphql";
import { formatDate } from "@/features/registrationTab/utils";
import { LICENSE_PLATE_TYPE_LABELS } from "@/features/registrationTab/constants";

import {
  LicensePlate,
  Address,
  DigitalCarReg,
  LicensePlateType,
} from "../../types";

interface WithRegistration {
  registration: DigitalCarReg;
}

const humanizeTaxCollectionFrequency = (value: string): string =>
  taxCollectionFrequencyLabels[value as TaxCollectionFrequency] ?? value;

const humanizePlateType = (plateType: LicensePlateType): string =>
  LICENSE_PLATE_TYPE_LABELS[plateType] ?? plateType;

const humanizeRegType = (regType: CarRegistrationType): undefined | string => {
  const mapping: Partial<Record<CarRegistrationType, string>> = {
    [RegistrationType.AhDigitalRegistration]: "Generic",
    [RegistrationType.AhDigitalRegistrationWithCustomPlate]: "Custom",
  };

  return regType in mapping ? mapping[regType] : undefined;
};

const formatLicensePlate = (licensePlate: LicensePlate): string =>
  [licensePlate.region, licensePlate.letters, licensePlate.digits].join(" ");

const formatAddress = (address: Address): ReactNode => {
  const parts = [
    [address.street, address.houseNumber].filter(Boolean).join(" "),
    address.zipcode,
    address.city,
    address.country,
  ].filter(Boolean);

  return parts.length
    ? parts.map((part, idx, arr) => (
        <Fragment key={`${part}-${idx}`}>
          {part}
          {idx + 1 < arr.length && <br />}
        </Fragment>
      ))
    : null;
};

const renderItem = (label: string, value: ReactNode, qaId: string) => (
  <Descriptions.Item
    className={`QA_ID_${qaId}`}
    label={label}
    style={{ paddingBottom: "4px" }}
  >
    {value || "-"}
  </Descriptions.Item>
);

const commonDescriptionsProps: DescriptionsProps = {
  column: 1,
  style: { marginBottom: "12px" },
};

export const VehicleDetailsContent = ({
  registration: { licensePlate, oldLicensePlate, ...registration },
}: WithRegistration) => (
  <Descriptions
    {...commonDescriptionsProps}
    title="Vehicle Details"
    className="QA_ID_vehicle_details"
  >
    {renderItem("VIN", registration.vin, "vin")}
    {renderItem(
      "License Plate",
      humanizeRegType(registration.registrationType),
      "license_plate"
    )}
    {registration.lowEmissionVehicle &&
      renderItem(
        "License Plate Type",
        licensePlate?.licensePlateType &&
          humanizePlateType(licensePlate.licensePlateType),
        "license_plate_type"
      )}
    {registration.registrationType ===
      RegistrationType.AhDigitalRegistrationWithCustomPlate && (
      <>
        {renderItem(
          "License Plate Number",
          licensePlate && formatLicensePlate(licensePlate),
          "license_plate_number"
        )}
        {renderItem(
          "License Plate PIN",
          registration.licensePlatePin,
          "license_plate_pin"
        )}
      </>
    )}
    {registration.lowEmissionVehicle &&
      renderItem(
        "Old License Plate Type",
        oldLicensePlate?.licensePlateType &&
          humanizePlateType(oldLicensePlate.licensePlateType),
        "old_license_plate_type"
      )}
    {renderItem(
      "Old License Plate",
      oldLicensePlate && formatLicensePlate(oldLicensePlate),
      "old_license_plate_number"
    )}
    {renderItem(
      "Reg. Certificate I Code",
      registration.registrationCertificate1Code,
      "reg_certificate_1_code"
    )}
    {renderItem(
      "Reg. Certificate I Code security code",
      registration.registrationCertificate1CodeSecurityCode,
      "reg_certificate_1_code_security_code"
    )}
    {renderItem(
      "Reg. Certificate II Number",
      registration.registrationCertificate2Number,
      "reg_certificate_2_number"
    )}
    {renderItem(
      "Reg. Certificate II Number security code",
      registration.registrationCertificate2NumberSecurityCode,
      "reg_certificate_2_number_security_code"
    )}
    {renderItem("EVB Number", registration.evbNumber, "evbNumber")}
    {renderItem(
      "Registered License Plate Number",
      registration.registeredLicensePlateNumber,
      "registered_license_plate_number"
    )}
    {renderItem(
      "Registration Date",
      registration.registrationDate &&
        formatDate(registration.registrationDate),
      "registration_date"
    )}
  </Descriptions>
);

export const HolderDetailsContent = ({
  registration: { holderPlaceOfBirth, customer, address },
}: WithRegistration) => (
  <Descriptions
    {...commonDescriptionsProps}
    title="Holder Details"
    className="QA_ID_holder_details"
  >
    {renderItem(
      "Full Name",
      [customer?.firstName, customer?.lastName].filter(Boolean).join(" "),
      "full_name"
    )}
    {renderItem(
      "Registration Address",
      address && formatAddress(address),
      "registration_address"
    )}
    {renderItem("Place of Birth", holderPlaceOfBirth, "place_of_birthday")}
    {renderItem(
      "Date of Birth",
      customer?.dateOfBirth && formatDate(customer.dateOfBirth),
      "date_of_birth"
    )}
  </Descriptions>
);

export const BankDetailsContent = ({
  registration: { vehicleTaxesCollectedPeriod, bankAccount },
}: WithRegistration) => {
  const details = bankAccount?.details;

  return (
    <Descriptions
      {...commonDescriptionsProps}
      title="Direct Debit Details"
      className="QA_ID_direct_debit_details"
    >
      {renderItem("IBAN", details?.iban, "iban")}
      {renderItem("BIC", details?.bic, "bic")}
      {renderItem("Bank Name", details?.bankName, "bank_name")}
      {renderItem("Account Holder Name", details?.holder, "holder_name")}
      {renderItem(
        "Tax Collection Frequency",
        vehicleTaxesCollectedPeriod &&
          humanizeTaxCollectionFrequency(vehicleTaxesCollectedPeriod),
        "reg_tax_collection_frequency"
      )}
    </Descriptions>
  );
};
